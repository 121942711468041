import React from 'react';

export const WithPoints = ({ Title , Description, Responsibilities }) => {
  // Split the responsibilities list into two halves for the two columns
  const midpoint = Math.ceil(Responsibilities.length / 2);
  const firstColumn = Responsibilities.slice(0, midpoint);
  const secondColumn = Responsibilities.slice(midpoint);

  return (
    <section className="wrapper !bg-[#ffffff]">
      <div className="container !pb-[0.5rem] xl:pb-10 lg:pb-10 md:pb-10 ">
        <div className="flex flex-wrap ">
          <div className=" w-full flex-[0_0_auto]  max-w-full !mx-auto">
            <div className="blog single">
                <div className="card-body flex-[1_1_auto] ">
                
                  <h3 className="h2 !mb-3 !mt-2 !leading-[1.35]">{Title}</h3>
                  <p>{Description}</p>
                  <div className="flex flex-wrap mx-[-15px] mt-[-15px] xl:mx-[-20px]">
                    {/* First Column */}
                    <div className="xl:w-6/12 w-full flex-[0_0_auto] xl:px-[20px] px-[15px] max-w-full mt-[15px]">
                      <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
                        {firstColumn.map((item, index) => (
                          <li key={index} className="relative pl-6 mt-[0.35rem]">
                            <span>
                              <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] absolute left-0"></i>
                            </span>
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* Second Column */}
                    <div className="xl:w-6/12 w-full flex-[0_0_auto] xl:px-[20px] px-[15px] max-w-full mt-[15px]">
                      <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
                        {secondColumn.map((item, index) => (
                          <li key={index} className="relative pl-6 mt-[0.35rem]">
                            <span>
                              <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] absolute left-0"></i>
                            </span>
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


{/* <WithPoints 
  Title="Job Responsibilities"
  Description="Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras justo odio, dapibus ac facilisis in, egestas eget quam."
  Responsibilities={[
    "Aenean eu leo quam ornare curabitur blandit tempus.",
    "Nullam quis risus eget urna mollis ornare donec elit.",
    "Etiam porta sem malesuada magna mollis euismod.",
    "Fermentum massa vivamus faucibus amet euismod.",
    "Additional responsibility 1.",
    "Additional responsibility 2."
  ]}
/> */}
