import React from 'react'

import {Section1} from '../../components/services/Section1'

import {Section7} from '../../components/services/Section7'
import serviceimg from   "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'




export const Proprietor = () => {

    
    return (
        <>
        <Section1 
        title="Proprietor Registration"
        subtitle={"Proprietor registration allows an individual to operate as a sole owner of the business, with complete control and responsibility, making it a simple and cost-effective option for small enterprises."}
        backgroundImage={serviceimg}/>


<WithoutPoints
Title="Proprietor Registration" 
Paragraphs={[
"A Proprietor happens to be the sole owner of a business making it ideal for traders and merchants who prefer to handle the day to day operation themselves. It is not protected by limited liability or continues to exist indefinitely nor can it be transferred easily or recognized as a corporate / legal entity. While it is extremely easy to comply with the proprietorship regulations, the liabilities are quite heavy too making it a risky venture in the long run. Since there is no formal registration of the company a proprietor does not have to submit any kind of documents for recognition of the business entity.",
]}/>

<WithoutPoints
Title="Background" 
Paragraphs={[
"There is no particular law for a sole Proprietorship business owner. The business endeavor and the owner are in distinguishable with all the profits as well as the liabilities going to the proprietor. The working capital is usually dependent on the personal finances of the owner and the business can be closed immediately should the proprietor choose to do so. The small merchants and traders usually opt for running a sole proprietorship business as there is no need to procure a minimum amount of money as investment or comply with legal formalities.",
]}/>


<WithoutPoints
Title="What is a Proprietorship Business" 
Paragraphs={[
"A business that depends on a single individual from its inception, setting up, and management with no other person having any control or say in the affairs of the business. The business can be started within two weeks with the help of a PAN card in the owner’s name, a few certificates along with a tidy sum of money as the working capital.",
]}/>


<WithPoints 
Title="Requirements for Starting a Proprietorship Business"

Responsibilities={[
"Single owner and manager of day to day operations.",
"The business can be made functional within a short period of time.",
"There is no standard registration rules applicable to  proprietorship businesses.",
"The owner should prove the existence of the business by providing other registration documents such as service tax & sales tax registrations",
]}
/>



<WithPoints 
Title="Advantages of Operating  A Proprietorship Business?"

Responsibilities={[
"Simple, uncomplicated process requiring  little or no formality.",
"It can be started by name since the business need not have a registered name. However, care must be taken to see that it is not violating the registered trademark code.",
"Ideal for traders and merchants who do not earn more than 3 lakhs as the company does not have to pay income tax. However, the no tax deductions offered may increase the liability of the owner inadvertently.",
"It can be set up and run by a single person exclusively with no need of any additional persons even a nominee director.",
"The company can be declared closed without any formality. There are absolutely no formalities involved here except the cancellation of service & sales tax registration.",
]}
/>

<WithPoints 
Title="Taxation Rules for a sole Proprietorship Business"

Responsibilities={[
"Since the firm or enterprise is not regarded as a legal entity, the onus of filing tax returns is on the owner of the company. The proprietor is taxed as an individual with a taxable income.",
"The total income from the business is calculated by checking the receipts and deducting the amount spent for business needs. Interest obtained from the bank and the amounts received as rents, salary are all added to arrive at the gross income figure.",
"The proprietor is eligible for IT deductions that are assessed as an individual operating a business.",
"However a proprietorship business owner is required to maintain a book of accounts, if the income from the business exceeds Rs 120000 in the year preceding to the one when the business was set up",
]}
/>





  <Section7 
sectionTitle="Join Our Community" 
description="We are trusted by over 5000+ clients. Join them now and grow your business." 
stats={[
{ value: '7518', label: 'Completed Projects' },
{ value: '5472', label: 'Satisfied Customers' },
{ value: '2184', label: 'Expert Employees' },
]}
/>

</>
  )
}
