
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './layouts/Main';
import PublicRoutes from './routes/PublicRoutes';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />}>
            {PublicRoutes.map((route, index) => (
              <Route path={route.path} element={route.element} key={index} />
            ))}
          </Route>
        </Routes>
      </Router>
      <Helmet>
     
        <script src=
          "./assets/js/theme.js"
          type="text/javascript"
        />
      </Helmet>
    </HelmetProvider>
  );
}


export default App;
