import React from 'react';

export const Section2 = ({ heading, subheading, description, cards, link }) => {
  return (
    <>
      <section className="wrapper !bg-[#ffffff]">
        <div className="container pt-[4.5rem] pb-14 xl:pt-24 lg:pt-24 md:pt-24 xl:pb-[4.5rem] lg:pb-[4.5rem] md:pb-[4.5rem]">
          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] lg:!mb-60 xl:!mb-80 items-center">
            
            <div className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] max-w-full xl:!order-2 lg:!order-2 mt-[50px]">
              <div className="flex flex-wrap mx-[-15px] xl:mx-[-12.5px] lg:mx-[-12.5px] md:mx-[-12.5px] mt-[-25px]">
                {cards.map((card, index) => (
                  <div
                    key={index}
                    className={`${
                      index % 2 === 0 ? 'xl:w-5/12 lg:w-5/12 md:w-5/12' : 'xl:w-6/12 lg:w-6/12 md:w-6/12'
                    } w-full flex-[0_0_auto] xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] px-[15px] max-w-full ${
                      index % 2 === 0 ? '!self-end' : '!self-start'
                    } mt-[25px]`}
                  >
                    <div className={`card !bg-[${card.bgColor}]`}>
                      <div  className="card-body p-[40px]">
                        <img
                          src={card.icon}
                          className={`svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] ${card.iconColor} !mb-3`}
                          alt="icon"
                        />
                        <h4>{card.title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: card.description }} className="!mb-0"></p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="xl:w-5/12 lg:w-5/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <h2 className="!text-[.75rem] uppercase text-[#aab0bc] !mb-3 tracking-[0.02rem] leading-[1.35]">{heading}</h2>
              <h3 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] !leading-[1.3] mb-5">{subheading}</h3>
              <p>{description}</p>
              <a href={link.href} className="btn btn-navy text-white !bg-[#343f52] border-[#343f52] hover:text-white hover:bg-[#343f52] hover:border-[#343f52] focus:shadow-[rgba(82,92,108,1)] active:text-white active:bg-[#343f52] active:border-[#343f52] disabled:text-white disabled:bg-[#343f52] disabled:border-[#343f52] !rounded-[50rem] mt-3 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">
                {link.text}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
