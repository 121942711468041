import React from 'react'

export const Contact = () => {
  return (
    <>
      <section class="wrapper  bg-[#21262c] opacity-100  text-white">
        <div class="container pt-12 pb-[12.5rem] !text-center">
          <div class="flex flex-wrap mx-[-15px]">
            <div class="sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-6/12 xxl:w-5/12 flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
              <h1 class="text-[calc(1.365rem_+_1.38vw)] font-bold leading-[1.2] xl:text-[2.4rem] text-white !mb-3">Get in Touch</h1>
              <p class="lead leading-[1.65] text-[0.9rem] font-medium xl:!px-10 xxl:!px-10">Have any questions? Reach out to us from our contact form and we will get back to you shortly.</p>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section class="wrapper !bg-[#ffffff]">
        <div class="container !pb-[4.5rem] xl:!pb-24 lg:!pb-24 md:!pb-24">
          <div class="flex flex-wrap mx-[-15px]">
            <div class="col flex-[0_0_auto] max-w-full px-[15px] relative z-[3] mt-[-9rem] mb-24">
              <div class="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                <div class="flex flex-wrap mx-0">
                  <div class="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] max-w-full image-wrapper bg-image rounded-t-[0.4rem] rounded-lg-start hidden xl:block lg:block md:block bg-cover bg-[center_center] bg-no-repeat !bg-scroll md:min-h-[25rem]" data-image-src="./assets/img/svg/get-in-touch.svg">
                  </div>
                  {/*/column */}
                  <div class="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] max-w-full">
                    <div class="p-10 md:!p-12 xl:!p-[4rem] lg:!p-[4rem]">
                      <h2 class="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] leading-[1.3] !mb-3">Let’s Talk</h2>
                      <p class="lead !text-[1.05rem] !leading-[1.6] font-medium">We're Here to Help You Succeed</p>
                      <p>Whether you're looking to start a business, file taxes, or protect your intellectual property, we’re here to provide the guidance and expertise you need. Reach out to us today to discuss how we can assist with your specific requirements.</p>
                      <a href="tel:+91 95909 09909" class="btn btn-primary !text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] !rounded-[50rem] !mt-2 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Call Us</a>

                    </div>
                    {/*/div */}
                  </div>
                  {/*/column */}
                </div>
                {/*/.row */}
              </div>
              {/* /.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div class="flex flex-wrap mx-[-15px]">
            <div class="xl:w-10/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
              <div class="flex flex-wrap mx-[-15px] mt-[-50px] xl:mx-[-35px] lg:mx-[-20px]">
                <div class="xl:w-8/12 lg:w-8/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] max-w-full mt-[50px]">
                  <form class="contact-form needs-validation" method="post" action="./assets/php/contact.php" novalidate>
                    <div class="messages"></div>
                    <div class="flex flex-wrap mx-[-10px]">
                      <div class="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                        <div class="form-floating relative !mb-4">
                          <input id="form_name" type="text" name="name" class=" form-control  relative block w-full text-[.75rem] font-medium text-[#60697b] bg-[#fefefe] bg-clip-padding border shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] rounded-[0.4rem] border-solid border-[rgba(8,60,130,0.07)] transition-[border-color] duration-[0.15s] ease-in-out focus:text-[#60697b] focus:bg-[rgba(255,255,255,.03)] focus:shadow-[0_0_1.25rem_rgba(30,34,40,0.04),unset] focus:!border-[rgba(63,120,224,0.5)] focus-visible:!border-[rgba(63,120,224,0.5)] focus-visible:!outline-0 placeholder:text-[#959ca9] placeholder:opacity-100 m-0 !pr-9 p-[.6rem_1rem] h-[calc(2.5rem_+_2px)] min-h-[calc(2.5rem_+_2px)] leading-[1.25]" placeholder="Jane" required />
                          <label for="form_name" class="text-[#959ca9] mb-2 inline-block text-[.75rem] absolute z-[2] h-full overflow-hidden text-start text-ellipsis whitespace-nowrap pointer-events-none border origin-[0_0] px-4 py-[0.6rem] border-solid border-transparent left-0 top-0 font-Manrope">First Name *</label>
                          <div class="valid-feedback"> Looks good! </div>
                          <div class="invalid-feedback"> Please enter your first name. </div>
                        </div>
                      </div>
                      {/* /column */}
                      <div class="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                        <div class="form-floating relative !mb-4">
                          <input id="form_lastname" type="text" name="surname" class=" form-control  relative block w-full text-[.75rem] font-medium text-[#60697b] bg-[#fefefe] bg-clip-padding border shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] rounded-[0.4rem] border-solid border-[rgba(8,60,130,0.07)] transition-[border-color] duration-[0.15s] ease-in-out focus:text-[#60697b] focus:bg-[rgba(255,255,255,.03)] focus:shadow-[0_0_1.25rem_rgba(30,34,40,0.04),unset] focus:!border-[rgba(63,120,224,0.5)] focus-visible:!border-[rgba(63,120,224,0.5)] focus-visible:!outline-0 placeholder:text-[#959ca9] placeholder:opacity-100 m-0 !pr-9 p-[.6rem_1rem] h-[calc(2.5rem_+_2px)] min-h-[calc(2.5rem_+_2px)] leading-[1.25]" placeholder="Doe" required />
                          <label for="form_lastname" class="text-[#959ca9] mb-2 inline-block text-[.75rem] absolute z-[2] h-full overflow-hidden text-start text-ellipsis whitespace-nowrap pointer-events-none border origin-[0_0] px-4 py-[0.6rem] border-solid border-transparent left-0 top-0 font-Manrope">Last Name *</label>
                          <div class="valid-feedback"> Looks good! </div>
                          <div class="invalid-feedback"> Please enter your last name. </div>
                        </div>
                      </div>
                      {/* /column */}
                      <div class="xl:w-12/12 lg:w-12/12 md:w-12/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                        <div class="form-floating relative !mb-4">
                          <input id="form_email" type="email" name="email" class=" form-control  relative block w-full text-[.75rem] font-medium text-[#60697b] bg-[#fefefe] bg-clip-padding border shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] rounded-[0.4rem] border-solid border-[rgba(8,60,130,0.07)] transition-[border-color] duration-[0.15s] ease-in-out focus:text-[#60697b] focus:bg-[rgba(255,255,255,.03)] focus:shadow-[0_0_1.25rem_rgba(30,34,40,0.04),unset] focus:!border-[rgba(63,120,224,0.5)] focus-visible:!border-[rgba(63,120,224,0.5)] focus-visible:!outline-0 placeholder:text-[#959ca9] placeholder:opacity-100 m-0 !pr-9 p-[.6rem_1rem] h-[calc(2.5rem_+_2px)] min-h-[calc(2.5rem_+_2px)] leading-[1.25]" placeholder="jane.doe@example.com" required />
                          <label for="form_email" class="text-[#959ca9] mb-2 inline-block text-[.75rem] absolute z-[2] h-full overflow-hidden text-start text-ellipsis whitespace-nowrap pointer-events-none border origin-[0_0] px-4 py-[0.6rem] border-solid border-transparent left-0 top-0 font-Manrope">Email *</label>
                          <div class="valid-feedback"> Looks good! </div>
                          <div class="invalid-feedback"> Please provide a valid email address. </div>
                        </div>
                      </div>
                      {/* /column */}
                      {/* <div class="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                      <div class="form-select-wrapper mb-4">
                        <select class="form-select" id="form-select" name="department" required>
                          <option selected disabled value="">Select a department</option>
                          <option value="Sales">Sales</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Customer Support">Customer Support</option>
                        </select>
                        <div class="valid-feedback"> Looks good! </div>
                        <div class="invalid-feedback"> Please select a department. </div>
                      </div>
                    </div> */}
                      {/* /column */}
                      <div class="w-full flex-[0_0_auto] px-[15px] max-w-full">
                        <div class="form-floating relative !mb-4">
                          <textarea id="form_message" name="message" class=" form-control  relative block w-full text-[.75rem] font-medium text-[#60697b] bg-[#fefefe] bg-clip-padding border shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] rounded-[0.4rem] border-solid border-[rgba(8,60,130,0.07)] transition-[border-color] duration-[0.15s] ease-in-out focus:text-[#60697b] focus:bg-[rgba(255,255,255,.03)] focus:shadow-[0_0_1.25rem_rgba(30,34,40,0.04),unset] focus:!border-[rgba(63,120,224,0.5)] focus-visible:!border-[rgba(63,120,224,0.5)] focus-visible:!outline-0 placeholder:text-[#959ca9] placeholder:opacity-100 m-0 !pr-9 p-[.6rem_1rem] h-[calc(2.5rem_+_2px)] min-h-[calc(2.5rem_+_2px)] leading-[1.25]" placeholder="Your message" style={{ height: "150px" }} required></textarea>
                          <label for="form_message" class="text-[#959ca9] mb-2 inline-block text-[.75rem] absolute z-[2] h-full overflow-hidden text-start text-ellipsis whitespace-nowrap pointer-events-none border origin-[0_0] px-4 py-[0.6rem] border-solid border-transparent left-0 top-0 font-Manrope">Message *</label>
                          <div class="valid-feedback"> Looks good! </div>
                          <div class="invalid-feedback"> Please enter your messsage. </div>
                        </div>
                      </div>
                      {/* /column */}
                      <div class="w-full flex-[0_0_auto] px-[15px] max-w-full">
                        <div class="form-check block min-h-[1.36rem] pl-[1.55rem] mb-4">
                          <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                          <label class="form-check-label" for="invalidCheck"> I agree to <a href="#" class="hover">terms and policy</a>. </label>
                          <div class="invalid-feedback"> You must agree before submitting. </div>
                        </div>
                      </div>
                      {/* /column */}
                      <div class="w-full flex-[0_0_auto] px-[15px] max-w-full">
                        <input type="submit" class="btn btn-primary text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] !rounded-[50rem] btn-send !mb-3 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]" value="Send message" />
                        <p class="text-[#aab0bc]"><strong>*</strong> These fields are required.</p>
                      </div>
                      {/* /column */}
                    </div>
                    {/* /.row */}
                  </form>
                  {/* /form */}
                </div>
                {/*/column */}
                <div class="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] max-w-full mt-[50px]">
                  <div class="flex flex-row">
                    <div>
                      <div class="icon text-[#3f78e0] xl:text-[1.4rem] text-[calc(1.265rem_+_0.18vw)] !mr-4 mt-[-0.25rem]"> <i class="uil uil-location-pin-alt before:content-['\ebd8']"></i> </div>
                    </div>
                    <div>
                      <h5 class="!mb-1">Address</h5>
                      <address class=" not-italic leading-[inherit] mb-4"> 1297, 30th Main Rd, Banashankari Stage II, Banashankari, Bengaluru, Karnataka 560070</address>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div>
                      <div class="icon text-[#3f78e0] xl:text-[1.4rem] text-[calc(1.265rem_+_0.18vw)] !mr-4 mt-[-0.25rem]"> <i class="uil uil-phone-volume before:content-['\ec50']"></i> </div>
                    </div>
                    <div>
                      <h5 class="!mb-1">Phone</h5>
                      <p>+91 95909 09909</p>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div>
                      <div class="icon text-[#3f78e0] xl:text-[1.4rem] text-[calc(1.265rem_+_0.18vw)] !mr-4 mt-[-0.25rem]"> <i class="uil uil-envelope before:content-['\eac8']"></i> </div>
                    </div>
                    <div>
                      <h5 class="!mb-1">E-mail</h5>
                      <p class="!mb-0"><a href="mailto:support@patsonlegal.com" class="text-[#60697b]">support@patsonlegal.com</a></p>

                    </div>
                  </div>
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <section class="wrapper !bg-[#ffffff]">
        <div class="container pb-16">
          {/* Flex container for side-by-side layout */}
          <div class="flex flex-col-6 md:flex-row gap-8 mx-auto max-w-6xl">
            {/* Card 2: Pune */}
            <div class="bg-white shadow-lg rounded-lg p-6 text-center border flex-1">
              <h5 class="font-bold mb-2 text-[#3f78e0]">Pune</h5>
              <p>No. 2, Vikrant Palace, Near Indian Petrol Bunk, Manikbag, Sinhagad Road, Pune - 411041</p>
              <p>Email: <a href="mailto:support@patsonlegal.com" class="text-[#60697b]">support@patsonlegal.com</a></p>
              <p>Phone: 09019949909</p>
            </div>

            {/* Card 3: Belgaum */}
            <div class="bg-white shadow-lg rounded-lg p-6 text-center border flex-1">
              <h5 class="font-bold mb-2 text-[#3f78e0]">Belgaum</h5>
              <p>Nalshan Heights 2nd Floor, Congress Road Tilakwadi, Belgaum - 590006</p>
              <p>Email: <a href="mailto:support@patsonlegal.com" class="text-[#60697b]">support@patsonlegal.com</a></p>
              <p>Phone: 09019949909</p>
            </div>
          </div>
        </div>
      </section>


      {/* /section */}
      <div class="wrapper !bg-[#ffffff]">
        <div class="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.3126238678715!2d77.55300167485752!3d12.926939188741702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d76d6e1af5%3A0x4cdaeda2a4b9b427!2s1297%2C%2030th%20Main%20Rd%2C%20Banashankari%20Stage%20II%2C%20Banashankari%2C%20Bengaluru%2C%20Karnataka%20560070!5e0!3m2!1sen!2sin!4v1645437305701!5m2!1sen!2sin" style={{ width: "100%", height: "500px", border: "0" }} allowfullscreen></iframe>
        </div>
        {/* /.map */}
      </div>
    </>
  )
}
