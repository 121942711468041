import React from 'react'

import {Section1} from '../../components/services/Section1'

import {Section7} from '../../components/services/Section7'
import serviceimg from   "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'



export const LimitedLiabilityPartnership = () => {

  
    
    return (
     <>
            <Section1 
            title="Limited Liability Partnership"
            subtitle={"A limited liability registration enables a group of individuals who want to do business to remain protected with a limited scope for being liable. It is no wonder therefore that most small businesses prefer to be registered as a LLP concern."}
            backgroundImage={serviceimg}/>


<WithoutPoints
  Title="What is a LLP?" 
  Paragraphs={[
    "The concept of limited liability was formulated in India in the year 2008 in accordance with the Limited Liability Partnership Act that began to be implemented a year later i.e. 2009. A Limited Liability Partnership Company, often known as LLP, enjoys a separate entity completely free from that of its owners. This provides the scope for acquiring assets in the name of the company and the LLP reserves the right to sue other legal entities when there is a violation of its rules.",
    "It can be defined as a corporate entity that combines both the professionalism of an enterprise as well as the initiatives of an entrepreneur skillfully. With flexible rules, the members can form an efficient partnership to carry out their business without hindrance.",
    "Statistics reveal that there are more than 10,000 partnership firms Registered as LLPs at present.",
  ]}/>
  

  <WithPoints 
  Title="Requirements for Registration of a Limited Liability Business"
  
  Responsibilities={[
    "Multiple partners",
    "Any of its partners is permitted to take charge and run the business as deemed fit",
    "Easy to comply with formalities.",
    "Selling of stocks to employees not permitted",
    "Venture capital or equity funds cannot be raised"
  ]}
/>

<WithPoints 
  Title="Advantages of registering your business as a limited liabilitycompany (LLP)?"
  
  Responsibilities={[
    "Simple, uncomplicated process requiring very less formality",
    "Every partner enjoys protection from the erroneous actions of other partners",
    "Ideal for family businesses or any business run by professionals along with micro business concerns",
    "A LLP can acquire assets or property and also incur debts. However, the partners remain protected and do not have to repay the creditors.",
    "The company can continue to function indefinitely unless dissolved in a legal manner",
    "The ownership can change hands due to death or in the eventuality of a partner/partners choosing to depart from the company.",
    "The company holdings can be transferred easily by addition of partners.",
    "A LLP does not need to be audited, if its annual turnover is less than 25 lakhs",
    "While the company can add assets or property in its name, none of the partners have any claim on its assets as long as it is a legal and solvent entity."
    

  ]}
/>

<WithPoints 
  Title="Taxation Rules for LLPs"
  
  Responsibilities={[
    "A flat rate of 30% is levied as income tax for all limited Liability Partnerships",
    "Profit made from selling of assets is taxable under Section 112",
    "Remuneration along with interest paid to the partners are taxable as per Section 40(b)",
    "The income enjoyed by partners according to their share is exempt from tax",
    "LLPs cannot avail of the benefits according to Section 44D of ‘Presumptive Taxation’ rules",
  ]}
/>


<WithPoints 
  Title="Documents required to register a LLP"
  
  Responsibilities={[
    "Self attested clear copy of PAN Card (2 copies) ( attested by gazetted officer or bank manager)",
    "Self attested clear copy of Address Proof - Voter ID/Adhaar Card/ Driving License/Passport(2 copies) ( attested by gazetted officer or bank manager)",
    "Self attested residential Proof (Bank Statement/Telephone Bill) ( attested by gazetted officer or bank manager)",
    "4 passport size photographs",
    "Registered Office Address Proof – Electricity Bill/BSNL (latest bill) along with Rent Agreement / ownership proof of proposed registered office",
    "Tax paid receipt if sale deed",
    "NOC"
  ]}
/>
<WithPoints 
  Title="You will get the documents after registration of your company"
  
  Responsibilities={[
    "DSC (Digital Signature Certificate) for 2 partners",
    "1 Copy of LLP agreement",
    "PAN",
    "DIN",
    "TAN",
    
  ]}
/>

      <Section7 
  sectionTitle="Join Our Community" 
  description="We are trusted by over 5000+ clients. Join them now and grow your business." 
  stats={[
    { value: '7518', label: 'Completed Projects' },
    { value: '5472', label: 'Satisfied Customers' },
    { value: '2184', label: 'Expert Employees' },
  ]}
/>

 </>
  )
}
