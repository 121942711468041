import React from 'react'

import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'
import Accordion from '../../components/accordion'




const accordionItems = [
    {
      heading: 'Frequently Asked Questions (FAQs) on Copyright Registration',
      title: 'What Is Copyright?',
        content: 'Copyright is a right given by the law to creators of literary, dramatic, musical and artistic works and producers of cinematograph films and sound recordings. In fact, it is a bundle of rights including, inter alia, rights of reproduction, communication to the public, adaptation and translation of the work. There could be slight variations in the composition of the rights depending on the work.',
    },
    {
      title: 'What Is The Scope Of Protection In The Copyright Act, 1957?',
        content: 'The Copyright Act, 1957 protects original literary, dramatic, musical and artistic works and cinematograph films and sound recordings from unauthorized uses. Unlike the case with patents, copyright protects the expressions and not the ideas. There is no copyright protection for ideas, procedures, methods of operation or mathematical concepts as such.',
    },
    {
      title: 'Does Copyright Apply To Titles And Names?',
        content: 'Copyright does not ordinarily protect titles by themselves or names, short word combinations, slogans, short phrases, methods, plots or factual information. Copyright does not protect ideas or concepts. To get the protection of copyright a work must be original.',
    },
    {
      title: 'Is It Necessary To Register A Work To Claim Copyright?',
        content: 'No. Acquisition of copyright is automatic and it does not require any formality. Copyright comes into existence as soon as a work is created and no formality is required to be completed for acquiring copyright. However, certificate of registration of copyright and the entries made therein serve as prima facie evidence in a court of law with reference to dispute relating to ownership of copyright.',
    },
    {
      title: 'What Is The Procedure For Registration Of A Work Under The Copyright Act, 1957?',
      Title: 'The procedure for registration is as follows:',
        Responsibilities: [
            'Application for registration is to be made on Form IV ( Including Statement of Particulars and Statement of Further Particulars) as prescribed in the first schedule to the Rules ;',
            'Separate applications should be made for registration of each work;',
            'Each application should be accompanied by the requisite fee prescribed in the second schedule to the Rules.',
            'The applications should be signed by the applicant or the advocate in whose favor a Vakalatnama or Power of Attorney has been executed. The Power of Attorney signed by the party and accepted by the advocate should also be enclosed.',
            'Each and every column of the Statement of Particulars and Statement of Further Particulars should be replied specifically.'
        ],
    },
    {
        title: 'What Are The Guidelines Regarding Registration Of A Work Under The Copyright Act?',
          content: 'Chapter VI of the Copyright Rules, 1958, as amended, sets out the procedure for the registration of a work. Copies of the Act and Rules can be obtained from the Manager of Publications, Publication Branch, Civil Lines, Delhi or his authorized dealers on payment or download from the Copyright Office web-site.',
      },
    {
        title: 'Whether Unpublished Works Are Registered?',
          content: 'Yes. Both published and unpublished works can be registered. Copyright in works published before 21st January, 1958, i.e., before the Copyright Act, 1957 came in force, can also be registered, provided the works still enjoy copyright. Three copies of published work may be sent along with the application. If the work to be registered is unpublished, a copy of the manuscript has to be sent along with the application for affixing the stamp of the Copyright Office in proof of the work having been registered. In case two copies of the manuscript are sent, one copy of the same duly stamped will be returned, while the other will be retained, as far as possible, in the Copyright Office for record and will be kept confidential. It would also be open to the applicant to send only extracts from the unpublished work instead of the whole manuscript and ask for the return of the extracts after being stamped with the seal of the Copyright Office. When a work has been registered as unpublished and subsequently it is published, the applicant may apply for changes in particulars entered in the Register of Copyright in Form V with prescribed fee. The process of registration and fee for registration of copyright is same.',
      },
    {
        title: 'Whether Computer Software Or Computer Programme Can Be Registered?',
          content: 'Yes. Computer Software or programme can be registered as a "work". As per Section 2 (o) of the Copyright Act, 1957 "literary work" includes computer programmes, tables and compilations, including computer databases. "Source Code" has also to be supplied along with the application for registration of copyright for software products.',
      },
    {
        title: 'How Can I Get Copyright Registration For My Web-Site?',
          content: 'A web-site contains several works such as literary works, artistic works (photographs etc.), sound recordings, video clips, cinematograph films and broadcastings and computer software too. Therefore, a separate application has to be filed for registration of all these works.',
      },
  ];



export const CopyrightRegistration = () => {
  

    return (
        <>
            <Section1
                title="Copyright Registration"
                subtitle={"Copyright registration safeguards your creative work, granting exclusive rights and protection against unauthorized reproduction or use."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="What Is Copyright?" 
            Paragraphs={[
              "Copyright is a right given by the law to creators of literary, dramatic, musical and artistic works and producers of cinematograph films and sound recordings. In fact, it is a bundle of rights including, inter alia, rights of reproduction, communication to the public, adaptation and translation of the work. There could be slight variations in the composition of the rights depending on the work.",
              "“Copyright” is a form of intellectual property protection granted to the creators of original works of authorship such as literary works (including computer programmes, tables and compilations), dramatic, musical and artistic works, cinematographic films and sound recordings.",
              "Copyright ensures certain minimum safeguards of the rights of authors over their creations, thereby protecting and rewarding creativity. Creativity being the keystone of progress, no civilized society can afford to ignore the basic requirement of encouraging the same. Economic and social development of a society is dependent on creativity. The protection provided by copyright to the efforts of writers, artists, designers, dramatists, musicians, architects and producers of sound recordings, cinematograph films and computer software, creates an atmosphere conducive to creativity, which induces them to create more and motivates others to create."
            ]}
            />

            <WithPoints 
            Title="The Copyrights Act Protects The Following Classes Of Work"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Dramatic and musical work (i.e. Drama, Skit, Play, Movie etc.)",
                "Artistic work (i.e. Logo, monogram, design etc.)",
                "Software / Computer Program (including website/brochure/profile)",
                "Work of Architecture",
                "Cinematograph Film",
                "Sound Recording (i.e. A Cassette or a Record)",
                "Website, Web Contents, Home Page"
            ]}
            /> 
            <Accordion
            items={accordionItems}
            />
            
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
