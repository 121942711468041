import React, { useState } from 'react';

export const Section4 = ({ sections }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleSection = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className="wrapper !bg-[#ffffff]">
         <div className="container ">
      <div className="container">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-500px] ${
              index % 2 !== 0 ? 'xl:flex-row-reverse' : ''
            } items-center !mb-[2rem] xl:!mb-10 lg:!mb-10 md:!mb-10`}
          >
            {/* FAQ Question */}
            <div className="w-full cursor-pointer" onClick={() => toggleSection(index)}>
              <h3 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.4rem] !leading-[1.3] flex items-center">
                <span>{section.question}</span>
                <span className="ml-3 text-red-500">{expandedIndex === index ? '-' : '+'}</span>
              </h3>
            </div>

            {/* FAQ Answer */}
            {expandedIndex === index && (
              <div className="w-full xl:px-[35px] lg:px-[20px] px-[15px]  max-w-full">
                <p className="!mb-6">{section.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      </div>
   
    </section>
  );
};
