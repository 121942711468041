import React from 'react'

import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'







//section 3





export const DesignRegistration = () => {



    return (
        <>
            <Section1
                title="Design Registration"
                subtitle={"Design registration protects the unique visual appearance of your product, ensuring exclusive rights to its aesthetic features and preventing unauthorized copying."}
                backgroundImage={serviceimg} />
            
            {/* <WithoutPoints
            Title="Design Registration" 
            Paragraphs={[
              "A design is regarded to be the intellectual property of its creator and can be protected by law from being copied, counterfeited or reused by rival companies and / or individuals. Any design created exclusively for an industry or process can be registered thus ensuring its protection."
            ]}
            /> */}
            <WithoutPoints
            Title="What is Design Registration?" 
            Paragraphs={[
              "Any design made with the aid of a shape, a pattern, composition, ornament or line can be registered and protected under the Designs Act of 2000. The law is equally applicable to two dimensional and three dimensional designs that are believed to be Intellectual Properties of an industry."
            ]}
            />

            <WithPoints 
            Title="What is protected by Design Registration?"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "The shape",
                "The configuration",
                "Lines",
                "Pattern / Patterns",
                "Ornamental design",
                "Color",
                "Combination of Colors, lines, and other forms",
                "The design registration application will be considered when it is used as a part of an Industrial Process and is totally visible",
                "It happens to be completely new and original"
            ]}
            /> 
            <WithPoints 
            Title="What is not considered to be a design?"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Books",
                "Clothing",
                "Stationary Items such as postcards or labels",
                "Booklet / Instructions describing the procedure of constructing an article",
                "Buildings and construction structures",
                "Parts of articles that are not sold in entirety",
                "Slight variations of a previously registered design",
                "Flags, symbols or emblems of a country",
                "Layout designs",
                "The design should be registered as a trademark or be copyrighted"
            ]}
            /> 
            <WithPoints 
            Title="Advantages of registering a design"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "The creator enjoys exclusive rights to use the design for a period of 10 years.",
                "The period can be extended for 5 more years.",
                "The designer is allowed to take legal action (according to the Design Act) for infringement.",
                "The Patent Office stores the registered designs and you are free to check the list from time to time in order to be apprised of new registered designs.",
                "Anyone found to be guilty of infringement is liable to pay the owner of the registered design a sum of Rs 25,000."
            ]}
            /> 
            <WithPoints 
            Title="Facts To Take Note Of"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Filing for a design registration needs to be done professionally to ensure no aspect gets ignored, as per the Design Act of 2000. This will expedite the process and increase the chances of successful registration.",
                "Applying for registration is not a guarantee of registration. The process is successfully completed only when the Registrar considers the premises along with the facts before agreeing to have the design protected.",
                "Individuals and companies can check the list of registered designs that is published and maintained by the Patent Office. Competitors can check whether a design has already been registered, making it impossible to use further.",
                "Any cause of infringement can be studied, and proper legal action can be taken against the offender in accordance with the Design Act.",
                "Checking the status of your application while it is being processed and responding timely to all requests, including sharing updated information with the office before the registration is complete, will help you achieve your objective.",
                "While designs for industrial processes are registered and protected for ten years with an option for extending the period for an additional five, designs that come under the purview of the Trademark or Copyright Act cannot be registered as a design."
            ]}
            /> 
            <WithoutPoints
            Title="Validity" 
            Paragraphs={[
              "The design remains registered for 10 years initially that can be further extended to an additional 5 years."
            ]}
            />
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
