import React from 'react'
// import home from '../assets/img/banner4.jpg';
import home from '../assets/img/svg/rocket-animate3.svg';
// import home from '../assets/img/rocket.jpg';
import icon1 from '../assets/img/icon01.png';
import icon2 from '../assets/img/icon02.png';
import icon3 from '../assets/img/icon03.png';
import icon4 from '../assets/img/icon04.png';
import icon5 from '../assets/img/icon05.png';
import icon6 from '../assets/img/icon06.png';
import Accordion from '../components/accordion';
import Banner from '../components/banner';
import { Section7 } from '../components/services/Section7';

export const Landing = () => {
  const accordionItems = [
    {
      title: 'Intellectual Property',
      content: 'We specialize in patent, trademark registration, and design as well as copyright. It also includes counseling, prosecution, licensing, and litigation. We also represent clients on IP issues related to international trade, portfolio management, the Internet, e-commerce, and domain name.',
    },
    {
      title: 'Chartered Accountants',
      content: 'We have been rendering a multitude of services under one roof over the past 7years, especially in the field of back office management services, audit and assurance, direct and indirect taxation, international taxation, corporate law, management consultancy, matters relating to FEMA, mergers and acquisitions, and other allied areas.',
    },
    {
      title: 'Company Secretaries',
      content: 'Professionally managed Practising Company Secretaries company in bangalore, India.we provides wide range of consultancy services to various medium and large sized corporates.The core area of specialisation of the firm is corporate secretarial compliances applicable to listed and unlisted companies, formation of Companies and Limited Liability Partnerships, corporate restructuring and due diligence, audit and certifications pertaining to various corporate laws and representation before various statutory authorities.',
    },
    {
      title: 'Patent',
      content: 'Our patent attorneys & consultants have advanced degrees in cutting-edge scientific disciplines. Through this powerful combination of legal and technical knowledge and strengths, we have become the largest and one of the most successful IP law firm in India.',
    },
  ];

  return (
    <>
      <section className="wrapper !bg-[#ffffff]">

        {/* <Banner /> */}
        {/* <div className="container pt-10 xl:pt-[4.5rem] lg:pt-[4.5rem] md:pt-[4.5rem] pb-8 !text-center"> */}
        <div className="container pt-0  pb-8 !text-center">
          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] justify-center items-center">
            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] px-[15px] xl:px-[35px] lg:px-[20px] mt-[50px] max-w-full">
              <figure className="m-0 p-0"><img className="" src={home}
                srcSet="../assets/img/svg/rocket-animate2.svg  2x" alt="image" /></figure>
            </div>

            <div
              className="md:w-10/12 lg:w-5/12 xl:w-5/12 md:!ml-[8.33333333%] lg:!ml-0 xl:!ml-0 flex-[0_0_auto] px-[15px] xl:px-[35px] lg:px-[20px] mt-[50px] max-w-full text-center lg:text-left xl:text-left">
              <h1
                className="text-[calc(1.175rem_+_1.5vw)] font-bold leading-[1.15] xl:text-[2rem] mb-5 md:mx-[-1.25rem] lg:mx-0">
                Your Path to Business Success Starts Here.</h1>
              <p className='lead !text-[1rem] leading-[1.55] mb-7'>Launch confidently with seamless business registration, tax filings, and IP protection. We’re here to handle the legal details so you can focus on growth</p>
              {/* <p className="lead !text-[1.1rem] leading-[1.55] mb-7">Most trusted and experienced team in Bangalore , Served more than 1500 entrepreneurs.</p> */}
              <span><a
                className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] !mr-2 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Try
                It For Free</a></span>
            </div>
            <h1 className="text-[1.5rem] tracking-[0.02rem] uppercase text-[#aab0bc] mt-5 !mb-1 !leading-[1.35]">Most trusted and experienced team in BANGALORE,</h1>
            <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25]  xl:!px-10">Served more than 1500 entrepreneurs.</h3>
          </div>

        </div>

      </section>

      <section className="wrapper bg-gradient-red ">
        <div className="container pt-[4.5rem] xl:pt-24 lg:pt-24 md:pt-24">
          <div className="flex flex-wrap mx-[-15px] !text-center">
            <div
              className="md:w-10/12 xl:w-8/12 lg:w-8/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:!ml-[16.66666667%] lg:!ml-[16.66666667%] md:!ml-[8.33333333%]">
              <h2 className="text-[0.8rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">What We Do?</h2>
              <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] mb-10 xl:!px-10">Deliver Independent, Innovative & Intelligent Services to Our Clients</h3>
            </div>

          </div>

          <div className="!relative">
            <div className="shape !rounded-[50%] !bg-[#edf2fc] rellax !w-[6rem] !h-[6rem] !absolute z-[1]"
              data-rellax-speed="1" style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: 0 }}
            ></div>
            <div
              className="shape bg-dot primary rellax !w-[6rem] !h-[7rem] absolute opacity-50 bg-[radial-gradient(#fab758_2px,transparent_2.5px)]"
              data-rellax-speed="1" style={{ bottom: "-0.5rem", left: "-2.2rem", zIndex: 0 }}></div>
            <div
              className="flex flex-wrap mx-[-15px] xl:mx-[-12.5px] lg:mx-[-12.5px] md:mx-[-12.5px] mt-[-25px] !text-center">
              <div
                className="md:w-6/12 lg:w-6/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px]">
                <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                  <div className="card-body flex-[1_1_auto] p-[40px]">
                    {/* <img src="./assets/img/icons/lineal/search-2.svg"
                      className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#fab758] text-yellow !mb-3 m-[0_auto]"
                      alt="image" /> */}
                    <div className="flex justify-center">
                      <img src={icon1} alt="image" className='' />
                    </div>
                    <h4 className="!text-[1rem]">Private Limited <br /> Company Registration</h4>
                    <p className="!mb-2">A private limited company includes a number of shareholders including foreign nationals. A business registered as a private limited company ...</p>
                    <a href="#" className="more hover text-[#fab758] focus:text-[#fab758] hover:text-[#fab758]">Learn More</a>
                  </div>

                </div>

              </div>

              <div
                className="md:w-6/12 lg:w-6/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px]">
                <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                  <div className="card-body flex-[1_1_auto] p-[40px]">
                    {/* <img src="./assets/img/icons/lineal/browser.svg"
                      className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#e2626b] text-red !mb-3 m-[0_auto]"
                      alt="image" /> */}
                    <div className="flex justify-center">
                      <img src={icon2} alt="image" className='' />
                    </div>
                    <h4 className="!text-[1rem]">Limited Liability Partnership Company Registration</h4>
                    <p className="!mb-2">A limited liability registration enables a group of individuals who want to do business to remain protected with a limited scope for being liable...</p>
                    <a href="#" className="more hover text-[#e2626b] focus:text-[#e2626b] hover:text-[#e2626b]">Learn More</a>
                  </div>

                </div>

              </div>

              <div
                className="md:w-6/12 lg:w-6/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px]">
                <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                  <div className="card-body flex-[1_1_auto] p-[40px]">
                    {/* <img src="./assets/img/icons/lineal/chat-2.svg"
                      className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#45c4a0] text-green !mb-3  m-[0_auto]"
                      alt="image" /> */}
                    <div className="flex justify-center">
                      <img src={icon3} alt="image" className='' />
                    </div>
                    <h4 className="!text-[1rem]">Importer Exporter Code <br />Registration</h4>
                    <p className="!mb-2">Every Person who is involved in international Trade should get IE Code (10 digit number Granted by Directorate General of Foreign Trade)...</p>
                    <a href="#" className="more hover text-[#45c4a0] focus:text-[#45c4a0] hover:text-[#45c4a0]">Learn More</a>
                  </div>

                </div>

              </div>

              <div
                className="md:w-6/12 lg:w-6/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px]">
                <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                  <div className="card-body flex-[1_1_auto] p-[40px]">
                    {/* <img src="./assets/img/icons/lineal/megaphone.svg"
                      className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#3f78e0] text-blue !mb-3  m-[0_auto]"
                      alt="image" /> */}
                    <div className="flex justify-center">
                      <img src={icon4} alt="image" className='' />
                    </div>
                    <h4 className="!text-[1rem]">TRADEMARK  </h4>
                    <p className="!mb-2">A TRADEMARK is either a word, phrase, symbol or design, or combination of words, phrases, symbols or designs, which identifies and distinguishes... <br /><br /></p>
                    <a href="#" className="more hover text-[#3f78e0] focus:text-[#3f78e0] hover:text-[#3f78e0]">Learn More</a>
                  </div>

                </div>

              </div>

              <div
                className="md:w-6/12 lg:w-6/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px]">
                <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                  <div className="card-body flex-[1_1_auto] p-[40px]">
                    {/* <img src="./assets/img/icons/lineal/megaphone.svg"
                      className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#3f78e0] text-blue !mb-3  m-[0_auto]"
                      alt="image" /> */}
                    <div className="flex justify-center">
                      <img src={icon5} alt="image" className='' />
                    </div>
                    <h4 className="!text-[1rem]">PATENT</h4>
                    <p className="!mb-2">Our patent attorneys & consultants have advanced degrees in cutting-edge scientific disciplines. Through this powerful combination of legal and technical...</p>
                    <a href="#" className="more hover text-[#3f78e0] focus:text-[#3f78e0] hover:text-[#3f78e0]">Learn More</a>
                  </div>

                </div>

              </div>

              <div
                className=" md:w-6/12 lg:w-6/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px]">
                <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                  <div className="card-body  flex-[1_1_auto] p-[40px]">
                    {/* <img src="./assets/img/icons/lineal/megaphone.svg"
                      className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#3f78e0] text-blue !mb-3  m-[0_auto]"
                      alt="image" /> */}
                    <div className="flex justify-center">
                      <img src={icon6} alt="image" className='' />
                    </div>
                    <h4 className="!text-[1rem]">COPYRIGHT</h4>
                    <p className="!mb-2 text-justify">Copyright is a right given by the law to creators of literary, dramatic, musical and artistic works and producers of cinematograph films and sound recordings...</p>
                    <a href="#" className="more hover text-[#3f78e0] focus:text-[#3f78e0] hover:text-[#3f78e0]">Learn More</a>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </section>



      <section className="wrapper bg-gradient-reverse-primary">
        <div className="container py-6">
          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px]  !mb-8 items-center">
            <div
              className="xl:w-5/12 lg:w-5/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full xl:!order-2 lg:!order-2">
              <figure className="m-0 p-0"><img className="w-auto" src="./assets/img/svg/judge-animate.svg"
                srcSet="./assets/img/about_patson.jpg 2x" alt="image" /></figure>
            </div>

            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              {/* <h2 className="text-[0.8rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">Analyze Now</h2> */}
              <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] mb-5">About Patson Legal</h3>
              <p className="!mb-7 text-[0.8rem]">Founded by young, dynamic and well qualified professionals, Patson legal knowledge process pvt. Ltd. provides their professional services to private and public like New company Registration, Secretarial services, Chartered accountant services Tax registration, Filing of various taxes and Intellectual Property Rights in India and Worldwide..
                <br /><br />
                Patson is a specialized team of , Chartered accountant, company Secretary , Trademark Agents, Patent attorneys, Corporate Lawyers and supporting staff. Our diversified team with extensive legal and corporate knowledge enables us to cater to the stated and unstated needs of our clients . It will be our pleasure to assist everyone and provide professional advice related to IP SERVICE. We are Specializes in Patent, trademark registration and design as well as copyright. It also includes counseling, prosecution, licensing, and litigation. We also represent clients on IP issues related to international trade, portfolio management, the Internet, e-commerce and domain name.</p>
              {/* <div className="flex flex-wrap mx-[-15px]">
                <div className="xl:w-9/12 lg:w-9/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                  <form action="#">
                    <div className="form-floating input-group relative flex flex-wrap items-stretch w-full">
                      <input type="url"
                        className="form-control  relative block w-full text-[.75rem] font-medium text-[#60697b] bg-[#fefefe] bg-clip-padding border shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] rounded-[0.4rem] border-solid border-[rgba(8,60,130,0.07)] transition-[border-color] duration-[0.15s] ease-in-out focus:text-[#60697b] focus:bg-[#fefefe] focus:shadow-[0_0_1.25rem_rgba(30,34,40,0.04),unset] focus:!border-[rgba(63,120,224,0.5)] focus-visible:!border-[rgba(63,120,224,0.5)] focus-visible:!outline-0 placeholder:text-[#959ca9] placeholder:opacity-100 m-0 !pr-9 p-[.6rem_1rem] h-[calc(2.5rem_+_2px)] min-h-[calc(2.5rem_+_2px)] leading-[1.25]"
                        placeholder="Enter Website URL" id="seo-check" />
                      <label htmlFor="seo-check"
                        className="text-[#959ca9] mb-2 inline-block text-[.75rem] absolute z-[2] h-full overflow-hidden text-start text-ellipsis whitespace-nowrap pointer-events-none border origin-[0_0] px-4 py-[0.6rem] border-solid border-transparent left-0 top-0 font-Manrope">Enter
                        Website URL</label>
                      <button
                        className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]"
                        type="button">Check</button>
                    </div>
                  </form>
                </div>

              </div> */}

            </div>

          </div>

        </div>

      </section>

      {/* <section className="wrapper !bg-[#edf2fc] aboutUs-bg">
        <div className="container py-[4.5rem] xl:!py-2 lg:!py-2 md:!py-2">
          <div className="flex flex-wrap mx-[-15px] mb-10">
            <div className="xl:w-10/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
              <div className="flex flex-wrap mx-[-15px] items-center counter-wrapper mt-[-30px] !text-center">
                <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                  <img src="./assets/img/icons/lineal/check.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                  <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">6000+</h3>
                  <p className=" text-[0.8rem] font-medium mb-0">Clients</p>
                </div>
        
                <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                  <img src="./assets/img/icons/lineal/user.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                  <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">15+</h3>
                  <p className=" text-[0.8rem] font-medium mb-0">Years in Business</p>
                </div>
         
                <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                  <img src="./assets/img/icons/lineal/briefcase-2.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                  <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">810</h3>
                  <p className=" text-[0.8rem] font-medium mb-0">Projects</p>
                </div>
    
                <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                  <img src="./assets/img/icons/lineal/award-2.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                  <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">34</h3>
                  <p className=" text-[0.8rem] font-medium mb-0">Strong Team</p>
                </div>
              
              </div>
             
            </div>
          
          </div>
         
        </div>
      
      </section> */}


      <section className="wrapper !bg-[#ffffff]  ">
        <Section7
          sectionTitle="Join Our Community"
          description="We are trusted by over 6000+ clients. Join them now and grow your business."
          stats={[
            { value: '15+', label: 'Years in Business' },
            { value: '810', label: 'Projects Completed' },
            { value: '50+', label: 'Team Members' },
          ]}
        />
      </section>




      <section className="wrapper !bg-[#ffffff]  ">
        <div className="container pt-[4.5rem] xl:pt-28 lg:pt-28 md:pt-28 ">
          {/* <div
            className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] md:mx-[-20px] mt-[-50px] !mb-[4.5rem] xl:!mb-[8rem] lg:!mb-[8rem] md:!mb-[8rem] items-center">
            <div
              className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:!order-2 lg:!order-2 xl:px-[35px] lg:px-[20px] md:px-[20px] mt-[50px]">
              <div className="card xl:!mr-6 lg:!mr-6 !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                <div className="card-body p-6">
                  <div className="flex flex-row">
                    <div>
                      <span
                        className="icon btn btn-circle btn-lg btn-soft-yellow pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span
                          className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">01</span></span>
                    </div>
                    <div>
                      <h4 className="!mb-1 text-[1rem]">Collect Ideas</h4>
                      <p className="!mb-0">Nulla vitae elit libero pharetra augue dapibus.</p>
                    </div>
                  </div>
                </div>

              </div>

              <div className="card mt-6 xl:!ml-16 lg:!ml-16 !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                <div className="card-body p-6">
                  <div className="flex flex-row">
                    <div>
                      <span
                        className="icon btn btn-circle btn-lg btn-soft-yellow pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span
                          className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">02</span></span>
                    </div>
                    <div>
                      <h4 className="!mb-1 text-[1rem]">Data Analysis</h4>
                      <p className="!mb-0">Vivamus sagittis lacus vel augue laoreet.</p>
                    </div>
                  </div>
                </div>

              </div>

              <div className="card mt-6 xl:mx-6 lg:mx-6 !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                <div className="card-body p-6">
                  <div className="flex flex-row">
                    <div>
                      <span
                        className="icon btn btn-circle btn-lg btn-soft-yellow pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span
                          className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">03</span></span>
                    </div>
                    <div>
                      <h4 className="!mb-1 text-[1rem]">Finalize Product</h4>
                      <p className="!mb-0">Cras mattis consectetur purus sit amet.</p>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div
              className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:px-[35px] lg:px-[20px] md:px-[20px] mt-[50px]">
              <h2 className="text-[0.8rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">Our Strategy
              </h2>
              <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] mb-5">Here are 3
                working steps to organize our business projects.</h3>
              <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Etiam porta sem malesuada
                magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam quis risus eget urna
                mollis.</p>
              <p className="!mb-6">Nullam id dolor id nibh ultricies vehicula ut id elit. Vestibulum id ligula porta felis
                euismod semper. Aenean lacinia bibendum nulla sed consectetur.</p>
              <a href="#"
                className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] !mb-0 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Learn
                More</a>
            </div>

          </div> */}

          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] lg:pb-10 xl:pb-10 items-center">
            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <figure className="m-0 p-0"><img className="w-auto" src="./assets/img/svg/seminar-pana.svg"
                srcSet="./assets/img/practice.jpg 2x" alt="image" /></figure>
            </div>

            <div
              className="xl:w-5/12 lg:w-5/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <h2 className="text-[1.9rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">OUR PRACTICE INCLUDES
              </h2>
              {/* <h3 className="text-[calc(1.315rem_+_0.78vw)] !leading-[1.25] font-bold xl:text-[1.9rem] mb-7">OUR PRACTICE INCLUDES</h3> */}
              <Accordion items={accordionItems} />


            </div>

          </div>

        </div>

      </section>



      <section className="wrapper !bg-[#ffffff]">
        <div className="container">
          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] items-center">
            <div
              className="xl:w-5/12 lg:w-5/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full xl:!order-2 lg:!order-2">
              <figure className="m-0 p-0"><img className="w-auto" src="./assets/img/svg/seo-animate.svg"
                srcSet="./assets/img/svg/seo-animate.svg 2x" alt="image" /></figure>
            </div>

            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <h2 className="text-[1.9rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">WHY CHOOSE US?
              </h2>
              {/* <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] mb-5">We make your
                spending stress-free for you to have the perfect control.</h3> */}
              <p className="!mb-6">Patson is a specialized team of Chartered accountant, company Secretary , Trademark Agents, Patent attorneys, Corporate Lawyers and supporting staff. Our diversified team with extensive legal and corporate knowledge enables us to cater to the stated and unstated needs of our clients. ll of our staff are driven by this client-focused tradition. As a result we are trusted to ensure our Legal Services are tailored to each client's specific situation Patson is Small enough to care, large enough to cope.</p>
              {/* <div className="flex flex-wrap mx-[-15px] mt-[-15px]">
                <div className="xl:w-6/12 w-full flex-[0_0_auto] px-[15px] mt-[15px] max-w-full">
                  <ul className="pl-0 list-none bullet-bg bullet-soft-primary  !mb-0">
                    <li className="relative pl-6"><span><i
                      className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#feeed9] text-[#fab758] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Aenean
                        quam ornare. Curabitur blandit.</span></li>
                    <li className="relative pl-6 mt-3"><span><i
                      className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#feeed9] text-[#fab758] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Nullam
                        quis risus eget urna mollis ornare.</span></li>
                  </ul>
                </div>

                <div className="xl:w-6/12 w-full flex-[0_0_auto] px-[15px] mt-[15px] max-w-full">
                  <ul className="pl-0 list-none bullet-bg bullet-soft-primary  !mb-0">
                    <li className="relative pl-6"><span><i
                      className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#feeed9] text-[#fab758] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Etiam
                        porta euismod malesuada mollis.</span></li>
                    <li className="relative pl-6 mt-3"><span><i
                      className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#feeed9] text-[#fab758] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Vivamus
                        sagittis lacus vel augue rutrum.</span></li>
                  </ul>
                </div>

              </div> */}

            </div>

          </div>

        </div>

      </section>



      {/* <section className="wrapper bg-gradient-reverse-primary">
        <div className="container py-[4.5rem] xl:!py-[8rem] lg:!py-[8rem] md:!py-[8rem]">
          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] items-center">
            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <div className="flex flex-wrap mx-[-15px] xl:mx-[-12.5px] lg:mx-[-12.5px] md:mx-[-12.5px] mt-[-25px]">
                <div
                  className="md:w-6/12 lg:w-6/12 xl:w-5/12 w-full flex-[0_0_auto] px-[15px] xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px] max-w-full !self-end">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body flex-[1_1_auto] p-[40px]">
                      <blockquote
                        className="text-[.9rem] leading-[1.7] font-medium pl-4 icon !mb-0 relative p-0 border-0 before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Cum sociis natoque penatibus et magnis dis parturient montes.”</p>
                        <div className="flex items-center text-left">
                          <div className="info p-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Coriss Ambady</h5>
                            <p className="!mb-0 !text-[.85rem]">Financial Analyst</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>

                  </div>

                </div>

                <div
                  className="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px] max-w-full !self-end">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body flex-[1_1_auto] p-[40px]">
                      <blockquote
                        className="text-[.9rem] leading-[1.7] font-medium pl-4 icon !mb-0 relative p-0 border-0 before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula
                          porta felis euismod.”</p>
                        <div className="flex items-center text-left">
                          <div className="info p-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Cory Zamora</h5>
                            <p className="!mb-0 !text-[.85rem]">Marketing Specialist</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>

                  </div>

                </div>

                <div
                  className="md:w-6/12 lg:w-6/12 xl:w-5/12 flex-[0_0_auto] px-[15px] xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px] max-w-full xl:!ml-[8.33333333%]">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body flex-[1_1_auto] p-[40px]">
                      <blockquote
                        className="text-[.9rem] leading-[1.7] font-medium pl-4 icon !mb-0 relative p-0 border-0 before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Donec id elit non porta gravida at eget metus. Duis mollis est luctus commodo nisi erat.”</p>
                        <div className="flex items-center text-left">
                          <div className="info p-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Barclay Widerski</h5>
                            <p className="!mb-0 !text-[.85rem]">Sales Specialist</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>

                  </div>

                </div>

                <div
                  className="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] xl:px-[12.5px] lg:px-[12.5px] md:px-[12.5px] mt-[25px] max-w-full !self-start">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body flex-[1_1_auto] p-[40px]">

                      <blockquote
                        className="text-[.9rem] leading-[1.7] font-medium pl-4 icon !mb-0 relative p-0 border-0 before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean eu leo pellentesque.”</p>
                        <div className="flex items-center text-left">
                          <div className="info p-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Jackie Sanders</h5>
                            <p className="!mb-0 !text-[.85rem]">Investment Planner</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>

                  </div>

                </div>

              </div>

            </div>

            <div
              className="xl:w-5/12 lg:w-5/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <h2
                className="text-[0.8rem] tracking-[0.02rem] !leading-[1.35] uppercase text-[#aab0bc] mb-3 xl:mt-[-1.5rem] lg:mt-[-1.5rem]">
                Our Community</h2>
              <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] mb-5">Don't take our
                word for it. See what customers are saying about us.</h3>
              <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl
                consectetur et. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Vestibulum
                id ligula porta felis euismod.</p>
              <a href="#"
                className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] mt-3 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">All
                Testimonials</a>
            </div>

          </div>

        </div>

      </section> */}

      <section className="wrapper bg-gradient-yellow">
        <div className="container py-10  xl:pb-32 lg:pb-32 md:pb-32">
          <div className="relative mt-8 ">
            <div className="flex flex-wrap mx-[-15px] !text-center">
              <div className="lg:w-6/12 xl:w-7/12 xxl:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
                <h2 className="text-[0.8rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">Our Team</h2>
                <h3
                  className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] mb-10 md:!px-16 lg:!px-4 xl:!px-0">
                  Think unique and be innovative. Make a difference with Sandbox.</h3>
              </div>

            </div>

            <div className="!relative">
              <div className="shape bg-dot blue rellax !w-[6rem] !h-[7rem] absolute opacity-50" data-rellax-speed="1"
                style={{ bottom: "0.5rem", right: "-1.7rem", zIndex: 0 }}></div>
              <div className="shape !rounded-[50%] bg-line red rellax !w-[6rem] !h-[6rem] absolute opacity-50"
                data-rellax-speed="1" style={{ top: "0.5rem", left: "-1.7rem", zIndex: 0 }}></div>
              <div className="flex flex-wrap mx-[-15px] grid-view mt-[-30px] xl:mt-0">
                <div className="md:w-6/12 lg:w-6/12 xl:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body p-[40px]">
                      <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatar/avatar1.jpg"
                        srcSet="./assets/img/avatars/te1@2x.jpg 2x" alt="image" />
                      <h4 className="!mb-1 text-[1rem]">Coriss Ambady</h4>
                      <div className="text-[.7rem] uppercase tracking-[0.02rem] font-bold text-[#aab0bc] mb-2">Financial
                        Analyst</div>
                      <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                      <nav className="nav social !mb-0">
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                      </nav>

                    </div>

                  </div>

                </div>

                <div className="md:w-6/12 lg:w-6/12 xl:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body p-[40px]">
                      <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatar/avatar5.jpg"
                        srcSet="./assets/img/avatars/te2@2x.jpg 2x" alt="image" />
                      <h4 className="!mb-1 text-[1rem]">Cory Zamora</h4>
                      <div className="text-[.7rem] uppercase tracking-[0.02rem] font-bold text-[#aab0bc] mb-2">Marketing
                        Specialist</div>
                      <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                      <nav className="nav social !mb-0">
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                      </nav>

                    </div>

                  </div>

                </div>

                <div className="md:w-6/12 lg:w-6/12 xl:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body p-[40px]">
                      <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatar/avatar4.jpg"
                        srcSet="./assets/img/avatars/te3@2x.jpg 2x" alt="image" />
                      <h4 className="!mb-1 text-[1rem]">Nikolas Brooten</h4>
                      <div className="text-[.7rem] uppercase tracking-[0.02rem] font-bold text-[#aab0bc] mb-2">Sales Manager
                      </div>
                      <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                      <nav className="nav social !mb-0">
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                      </nav>

                    </div>

                  </div>

                </div>

                <div className="md:w-6/12 lg:w-6/12 xl:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full">
                  <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body p-[40px]">
                      <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatar/avatar2.jpg"
                        srcSet="./assets/img/avatars/te4@2x.jpg 2x" alt="image" />
                      <h4 className="!mb-1 text-[1rem]">Jackie Sanders</h4>
                      <div className="text-[.7rem] uppercase tracking-[0.02rem] font-bold text-[#aab0bc] mb-2">Investment
                        Planner</div>
                      <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                      <nav className="nav social !mb-0">
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                        <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]"
                          href="#"><i
                            className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                      </nav>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </section>

      {/* <section
        className="wrapper !bg-[#ffffff]  angled upper-end lower-end relative border-0 before:top-[-4rem] before:border-l-transparent before:border-r-[100vw] before:border-t-[4rem] before:border-[#fefefe] before:content-[''] before:block before:absolute before:z-0 before:!border-y-transparent before:border-0 before:border-solid before:right-0 after:bottom-[-4rem] after:border-l-transparent after:border-r-[100vw] after:border-b-[4rem] after:border-[#fefefe] after:content-[''] after:block after:absolute after:z-0 after:!border-y-transparent after:border-0 after:border-solid after:right-0">
        <div className="container py-[4.5rem] xl:pt-[4.5rem] lg:pt-[4.5rem] md:pt-[4.5rem] xl:pb-32 lg:pb-32 md:pb-32">
          <div className="flex flex-wrap mx-[-15px] mt-[-30px] !mb-[4.5rem] xl:!mb-[8rem] lg:!mb-[8rem] md:!mb-[8rem]">
            <div className="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
              <h2
                className="text-[0.8rem] tracking-[0.02rem] !leading-[1.35] uppercase text-[#aab0bc] xl:!mt-[8rem] lg:!mt-[8rem] !mb-3">
                Our Pricing</h2>
              <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] !mb-3">We offer great
                and premium prices.</h3>
              <p>Enjoy a <a href="#" className="hover !text-[#fab758]">free 30-day trial</a> and experience the full service.
                No credit card required!</p>
              <a href="#"
                className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] !mt-2 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">See
                All Prices</a>
            </div>

            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:!ml-[8.33333333%] lg:!ml-[8.33333333%] pricing-wrapper mt-[30px]">
              <div className="flex flex-wrap items-center switcher xl:!justify-end lg:!justify-end">
                <p className="!mb-0 !pr-[.75rem]">Monthly</p>
                <div
                  className="pricing-switchers w-8 h-4 clear-both !text-center !relative bg-[rgba(30,34,40,0.07)] box-content rounded-3xl border-[0.2rem] border-solid border-transparent">
                  <div
                    className="pricing-switcher pricing-switcher-active cursor-pointer w-full float-left h-4 leading-4 !relative z-[888] transition-[0.3s] duration-[ease-in-out] uppercase text-white">
                  </div>
                  <div
                    className="pricing-switcher cursor-pointer w-full float-left h-4 leading-4 !relative z-[888] transition-[0.3s] duration-[ease-in-out] uppercase">
                  </div>
                  <div
                    className="h-4 w-4 block absolute z-[555] transition-[0.3s] duration-[ease-in-out] m-0 rounded-[100%] border-[none] left-0 top-0 !bg-[#fab758] opacity-100 switcher-button">
                  </div>
                </div>
                <p className="!mb-0 !pl-3 !relative">Yearly <span className="text-[#e2626b]">(Save 30%)</span></p>
              </div>
              <div className="flex flex-wrap mx-[-15px] mt-[25px] !relative">
                <div
                  className="shape bg-dot red rellax !w-[6rem] !h-[8rem] absolute z-[1] bg-[radial-gradient(#e2626b_2px,transparent_2.5px)] opacity-50"
                  data-rellax-speed="1" style={{ bottom: "-0.5rem", right: "-1.6rem" }}></div>
                <div className="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                  <div className="pricing card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body !p-[3rem_40px_3.5rem_40px]">
                      <div className="prices text-[#343f52]">
                        <div className="price price-show !justify-start"><span className="price-currency">$</span><span
                          className="price-value">19</span> <span className="price-duration">mo</span></div>
                        <div className="price price-hide price-hidden !justify-start"><span
                          className="price-currency">$</span><span className="price-value">199</span> <span
                            className="price-duration">yr</span></div>
                      </div>

                      <h4 className="card-title !mt-2">Premium Plan</h4>
                      <ul className="pl-0 list-none bullet-bg bullet-soft-primary mt-7 !mb-8">
                        <li className="relative pl-[1.25rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span><strong>5</strong>
                            Projects </span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span><strong>100K</strong>
                            API Access </span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span><strong>200MB</strong>
                            Storage </span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span>
                            Weekly <strong>Reports</strong></span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span>
                            7/24 <strong>Support</strong></span></li>
                      </ul>
                      <a href="#"
                        className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Choose
                        Plan</a>
                    </div>

                  </div>

                </div>

                <div className="xl:w-6/12 lg:w-6/12 md:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full popular mt-[30px]">
                  <div className="pricing card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                    <div className="card-body !p-[3rem_40px_3.5rem_40px]">
                      <div className="prices text-[#343f52]">
                        <div className="price price-show !justify-start"><span className="price-currency">$</span><span
                          className="price-value">49</span> <span className="price-duration">mo</span></div>
                        <div className="price price-hide price-hidden !justify-start"><span
                          className="price-currency">$</span><span className="price-value">499</span> <span
                            className="price-duration">yr</span></div>
                      </div>

                      <h4 className="card-title !mt-2">Corporate Plan</h4>
                      <ul className="pl-0 list-none bullet-bg bullet-soft-primary mt-7 !mb-8">
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span><strong>20</strong>
                            Projects </span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span><strong>300K</strong>
                            API Access </span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span><strong>500MB</strong>
                            Storage </span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span>
                            Weekly <strong>Reports</strong></span></li>
                        <li className="relative pl-[1.25rem] mt-[0.35rem]"><i
                          className="uil uil-check absolute left-0 top-[-0.2rem]  !text-[1.05rem] tracking-[normal] !text-center flex items-center justify-center text-[#fab758] rounded-[100%] before:content-['\e9dd'] before:align-middle before:table-cell"></i><span>
                            7/24 <strong>Support</strong></span></li>
                      </ul>
                      <a href="#"
                        className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Choose
                        Plan</a>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

          <div
            className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] mb-10 xl:!mb-[4.5rem] lg:!mb-[4.5rem] md:!mb-[4.5rem] items-center">
            <div
              className="xl:w-7/12 lg:w-7/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <figure className="m-0 p-0"><img className="w-auto" src="./assets/img/illustrations/i5.png"
                srcSet="./assets/img/illustrations/i5@2x.png 2x" alt="image" /></figure>
            </div>

            <div
              className="xl:w-5/12 lg:w-5/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <h2 className="text-[0.8rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">Let’s Talk</h2>
              <h3 className="text-[calc(1.315rem_+_0.78vw)] font-bold xl:text-[1.9rem] !leading-[1.25] !mb-3">Let's make
                something great together. We are trusted by over 5000+ clients.</h3>
              <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Maecenas faucibus mollis
                interdum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa
                justo sit amet risus.</p>
              <a href="#"
                className="btn btn-yellow text-white !bg-[#fab758] border-[#fab758] hover:text-white hover:bg-[#fab758] hover:border-[#fab758] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#fab758] active:border-[#fab758] disabled:text-white disabled:bg-[#fab758] disabled:border-[#fab758] !text-[.85rem] !rounded-[50rem] !mt-2 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Join
                Us</a>
            </div>

          </div>

          <div className="xl:!px-5 lg:!px-5">
            <div className="flex flex-wrap mx-0 md:mx-[-20px] lg:mx-[-20px] xl:mx-[-35px] mt-[-40px] items-center">
              <div
                className="w-4/12 xl:w-2/12 lg:w-2/12 md:w-2/12 flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[40px] max-w-full">
                <figure className="px-5 md:!px-0 lg:!px-2 xl:!px-3 xxl:!px-4"><img src="./assets/img/brands/c1.png"
                  alt="image" /></figure>
              </div>

              <div
                className="w-4/12 xl:w-2/12 lg:w-2/12 md:w-2/12 flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[40px] max-w-full">
                <figure className="px-5 md:!px-0 lg:!px-2 xl:!px-3 xxl:!px-4"><img src="./assets/img/brands/c2.png"
                  alt="image" /></figure>
              </div>

              <div
                className="w-4/12 xl:w-2/12 lg:w-2/12 md:w-2/12 flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[40px] max-w-full">
                <figure className="px-5 md:!px-0 lg:!px-2 xl:!px-3 xxl:!px-4"><img src="./assets/img/brands/c3.png"
                  alt="image" /></figure>
              </div>

              <div
                className="w-4/12 xl:w-2/12 lg:w-2/12 md:w-2/12 flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[40px] max-w-full">
                <figure className="px-5 md:!px-0 lg:!px-2 xl:!px-3 xxl:!px-4"><img src="./assets/img/brands/c4.png"
                  alt="image" /></figure>
              </div>

              <div
                className="w-4/12 xl:w-2/12 lg:w-2/12 md:w-2/12 flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[40px] max-w-full">
                <figure className="px-5 md:!px-0 lg:!px-2 xl:!px-3 xxl:!px-4"><img src="./assets/img/brands/c5.png"
                  alt="image" /></figure>
              </div>

              <div
                className="w-4/12 xl:w-2/12 lg:w-2/12 md:w-2/12 flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[40px] max-w-full">
                <figure className="px-5 md:!px-0 lg:!px-2 xl:!px-3 xxl:!px-4"><img src="./assets/img/brands/c6.png"
                  alt="image" /></figure>
              </div>

            </div>

          </div>

        </div>

      </section> */}
    </>
  )
}
