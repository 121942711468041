import React from 'react'

import {Section1} from '../../components/services/Section1'

import {Section7} from '../../components/services/Section7'
import serviceimg from   "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'


export const PrivateLimitedCompany = () => {

  
    
    return (
        <>
            <Section1 
            title="Private Limited Company Registration"
            subtitle={"Private Limited Company registration offers limited liability protection to shareholders, enabling a structured business with restricted share transfer and compliance benefits ideal for growing businesses."}
            backgroundImage={serviceimg}/>


<WithoutPoints
Title="What is a Private Limited Company?" 
Paragraphs={[
    "A private limited company includes a number of shareholders including foreign nationals. A business registered as a private limited company needs to follow the rules incorporated in the ‘Companies Act, 2013’ as well as the ‘Companies Incorporation Rules, 2014.",
    "Almost all private business holdings in India prefer to register their businesses as a Private Limited Company on basis of the advantages offered to this entity. The members of the company are restricted from a minimum of two to a maximum of 200 with the assets liable to be sold off, if fails to pay off the loan amount taken from banks/financial institutes/ and other creditors. The Directors of the company enjoy a limited liability for paying off the creditors as their personal property / assets cannot be sold off to pay off the loan amount taken for the business.",
  ]}/>


<WithPoints 
Title="Requirements for Registration of a Private Limited Company"

Responsibilities={[
"At least two shareholders along with two directors are required to be associated with the company.",
"At least 1 director should be a resident of India.",
"At least Rs 1,00,000 as Share Capital.",
"While an individual can be a shareholder as well as a director, a legal corporate entity can only be a shareholder of the company",
"The law allows foreign funding thus making it the most popular form of business registration",
"Foreign nationals, NRIs, and foreign corporate entities are legally allowed to be shareholders and/or directors."
]}
/>



<WithPoints 
Title="Documents required to Register a Private Limited Company"

Responsibilities={[
"Self attested PAN Card (2 Copies) ( attested by gazetted officer or bank manager).",
"Self attested Address Proof - Voter ID/Adhaar Card/ Driving License/Passport  (2 Copies) (attested by gazetted officer or bank manager).",
"6 passport size photographs.",
"Self attested Bank Statement/ Electricity Bill/ Mobile Bill/ telephone Bill not older than one month (passbook copy is not accepted).",
"Rental agreement and electricity bill of office address(Electricity bill not older than 1 month) if rented premises.",
"NOC",
"BBMP tax paid receipt and electricity bill of office address(Electricity bill not older than 1 month) if own premises.",
]}
/>

<WithPoints 
Title="Company Registration Process"

Responsibilities={[
"Choose unique & new business name first.",
"Decide the type of business structure for your company.",
"Apply and obtain your name approval from MCA.",
"Obtain DSC (Digital Signature Certificate) for directors",
"Register your company in MCA portal (Apply DIN for directors, Draft company’s MOA & AOA, Incorporate company by uploading Forms, Apply PAN & TAN of company)",
"Get Company Incorporation of Certificate (COI)",

]}
/>

<WithPoints 
Title="You will get the documents after Registration of your Company"

Responsibilities={[
"DSC for 2 director  (Digital Signature Certificate)",
"DIN for directors  (Directors Identification Number)",
"MOA (Memorandum of association)",
"AOA ( Articles of association)",
"Company Incorporation",
"PAN",
"TAN",
]}
/>
<WithPoints 
Title="Taxation Rules for private limited companies"

Responsibilities={[
"It is required by law to get the company accounts audited on a yearly basis",
"Income Tax return for the company should be filed before the due date",
"Details of the company to be submitted to the ‘Registrar of Companies’  every year by filing balance sheet.",
]}
/>



      <Section7 
  sectionTitle="Join Our Community" 
  description="We are trusted by over 5000+ clients. Join them now and grow your business." 
  stats={[
    { value: '7518', label: 'Completed Projects' },
    { value: '5472', label: 'Satisfied Customers' },
    { value: '2184', label: 'Expert Employees' },
  ]}
/>

 </>
  )
}
