import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
  
    <footer className="!bg-[#21262c] shrink-0">
    <div className="container py-16 xl:!py-20 lg:!py-20 md:!py-20">
      <div className="flex flex-wrap mx-[-15px] mt-[-30px] xl:mt-0 lg:mt-0">
        <div className="md:w-4/12 xl:w-3/12 lg:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:mt-0 lg:mt-0 mt-[30px]">
          <div className="widget text-[#cacaca]">
            <img className=""/>
            <p className="!mb-4">© 2024 Patson Legal. <br className="hidden xl:block lg:block text-[#cacaca]"/>All rights reserved.</p>
            <nav className="nav social social-white">
              <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-twitter before:content-['\ed59'] !text-white text-[1rem]"></i></a>
              <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] !text-white text-[1rem]"></i></a>
              <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] !text-white text-[1rem]"></i></a>
              <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-instagram before:content-['\eb9c'] !text-white text-[1rem]"></i></a>
              <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-youtube before:content-['\edb5'] !text-white text-[1rem]"></i></a>
            </nav>        
          </div>        
        </div>
   
        <div className="md:w-4/12 xl:w-3/12 lg:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:mt-0 lg:mt-0 mt-[30px]">
          <div className="widget text-[#cacaca]">
            <h4 className="widget-title text-white !mb-3">Get in Touch</h4>
            <address className="xl:pr-20 xxl:!pr-28 not-italic leading-[inherit] block mb-4">No.1297, 3rd Floor, 30th main, B.S.K. 2nd stage, Bangalore- 560070.</address>
            <a className="text-[#cacaca] hover:text-[#3f78e0]" href="mailto:support@patsonlegal.com">support@patsonlegal.com</a> <br/> +91 95-9090-9909
          </div>
    
        </div>
     
        <div className="md:w-4/12 xl:w-3/12 lg:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:mt-0 lg:mt-0 mt-[30px]">
          <div className="widget text-[#cacaca]">
            <h4 className="widget-title text-white !mb-3">Learn More</h4>
            <ul className="pl-0 list-none   !mb-0">
              <li><a className="text-[#cacaca] hover:text-[#3f78e0]" href="/aboutUs">About Us</a></li>
              <li className="mt-[0.35rem]"><a className="text-[#cacaca] hover:text-[#3f78e0]" href="#">Our Story</a></li>
              <li className="mt-[0.35rem]"><a className="text-[#cacaca] hover:text-[#3f78e0]" href="#">Projects</a></li>
              <li className="mt-[0.35rem]"><a className="text-[#cacaca] hover:text-[#3f78e0]" href="#">Terms of Use</a></li>
              <li className="mt-[0.35rem]"><a className="text-[#cacaca] hover:text-[#3f78e0]" href="#">Privacy Policy</a></li>
            </ul>
          </div>
       
        </div>
       
        <div className="md:w-full xl:w-3/12 lg:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full xl:mt-0 lg:mt-0 mt-[30px]">
          <div className="widget text-[#cacaca]">
            <h4 className="widget-title text-white !mb-3">Our Newsletter</h4>
            <p className="!mb-5">Subscribe to our newsletter to get our news & deals delivered to you.</p>
            <div className="newsletter-wrapper">
             
              <div id="mc_embed_signup2">
                <form action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&amp;id=b49ef47a9a" method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate dark-fields" target="_blank" noValidate>
                  <div id="mc_embed_signup_scroll2">
                    <div className="!text-left input-group form-floating !relative flex flex-wrap items-stretch w-full">
                      <input type="email"  name="EMAIL" className="required email form-control block w-full text-[12px] font-medium leading-[1.7] appearance-none bg-clip-padding shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] px-4 py-[0.6rem] rounded-[0.4rem] motion-reduce:transition-none focus:text-[#60697b] focus:bg-[rgba(255,255,255,.03)] focus:shadow-[0_0_1.25rem_rgba(30,34,40,0.04),unset] disabled:bg-[#aab0bc] disabled:opacity-100 file:mt-[-0.6rem] file:mr-[-1rem] file:mb-[-0.6rem] file:ml-[-1rem] file:text-[#60697b] file:bg-[#fefefe] file:pointer-events-none file:transition-all file:duration-[0.2s] file:ease-in-out file:px-4 file:py-[0.6rem] file:rounded-none motion-reduce:file:transition-none placeholder:text-[#959ca9] placeholder:opacity-100 border border-solid !border-[rgba(255,255,255,0.1)] text-[#cacaca] focus:!border-[rgba(63,120,224,0.5)] bg-[rgba(255,255,255,.03)] focus-visible:!border-[rgba(63,120,224,0.5)] focus-visible:!outline-0" placeholder="Email Address" id="mce-EMAIL2"/>
                      <label className="!ml-[0.05rem] text-[#959ca9] text-[.75rem] absolute z-[2] h-full overflow-hidden text-start text-ellipsis whitespace-nowrap pointer-events-none origin-[0_0] px-4 py-[0.6rem] left-0 top-0" htmlFor="mce-EMAIL2">Email Address</label>
                      <input type="submit" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-primary text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] !relative z-[2] focus:z-[5] hover:!transform-none border-0"/>
                    </div>
                    <div id="mce-responses2" className="clear">
                      <div className="response" id="mce-error-response2" style={{display:"none"}}></div>
                      <div className="response" id="mce-success-response2" style={{display:"none"}}></div>
                    </div> 
                    <div style={{position:"absolute",left:"-5000px"}}  aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabIndex="-1" /></div>
                    <div className="clear"></div>
                  </div>
                </form>
              </div>
          
            </div>
          
          </div>
         
        </div>
       
      </div>
     
    </div>
   
  </footer>
  )
}