import React from 'react';

export const Section6 = ({ pricingData, switchToYearly, switchToMonthly }) => {
  return (
    <section className="wrapper !bg-[#ffffff]">
      <div className="container py-[4.5rem] xl:!py-24 lg:!py-24 md:!py-24">
        <div className="pricing-wrapper !relative z-[3] mt-[-15rem] xl:!mt-[-20rem] lg:!mt-[-20rem] md:!mt-[-20rem]">
          <div className="shape bg-dot primary rellax !w-[6rem] !h-[8rem] bg-[radial-gradient(#3f78e0_2px,transparent_2.5px)] absolute z-[1] opacity-50" data-rellax-speed="1" style={{top: "2rem", right:" -2.4rem"}}></div>
          <div className="shape !rounded-[50%] bg-line red rellax !w-[8rem] !h-[8rem] hidden xl:block lg:block absolute z-[1] opacity-50" data-rellax-speed="1" style={{bottom:" 0.5rem", left:" -2.5rem"}}></div>
          
          {/* Pricing Switcher */}
          <div className="flex flex-wrap items-center justify-center pricing-switcher-wrapper switcher">
            <p className="!mb-0 !pr-[.75rem]">Monthly</p>
            <div className="pricing-switchers w-8 h-4 clear-both !text-center !relative bg-[rgba(30,34,40,0.07)] box-content rounded-3xl border-[0.2rem] border-solid border-transparent">
              <div className="pricing-switcher pricing-switcher-active cursor-pointer w-full float-left h-4 leading-4 !relative z-[888] transition-[0.3s] duration-[ease-in-out] uppercase text-white" onClick={switchToMonthly}></div>
              <div className="pricing-switcher cursor-pointer w-full float-left h-4 leading-4 !relative z-[888] transition-[0.3s] duration-[ease-in-out] uppercase" onClick={switchToYearly}></div>
              <div className="h-4 w-4 block absolute z-[555] transition-[0.3s] duration-[ease-in-out] m-0 rounded-[100%] border-[none] left-0 top-0 !bg-[rgba(63,120,224)] opacity-100 switcher-button"></div>
            </div>
            <p className="!mb-0 !pl-3 !relative">Yearly</p>
          </div>

          {/* Pricing Cards */}
          <div className="flex flex-wrap mx-[-15px] mt-3 xl:!mt-5 lg:!mt-5 md:!mt-5">
            {pricingData.map((plan, index) => (
              <div key={index} className="md:w-6/12 lg:w-4/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                <div className="pricing card !text-center">
                  <div className="card-body flex-[1_1_auto] pb-4 xl:p-[2rem_2.5rem_1.25rem] lg:p-[2rem_2.5rem_1.25rem] md:p-[2rem_2.5rem_1.25rem]">
                    <img src={plan.icon} className="svg-inject icon-svg text-[#3f78e0] mb-3 !w-[2.6rem] !h-[2.6rem] m-[0_auto]" alt="image" />
                    <h4 className="card-title">{plan.title}</h4>
                    <div className="prices text-[#343f52]">
                      <div className="price price-show"><span className="price-currency">$</span><span className="price-value">{plan.priceMonthly}</span> <span className="price-duration">mo</span></div>
                      <div className="price price-hide price-hidden"><span className="price-currency">$</span><span className="price-value">{plan.priceYearly}</span> <span className="price-duration">yr</span></div>
                    </div>
                    <ul className="pl-0 list-none bullet-bg bullet-soft-primary mt-7 !mb-8 text-left">
                      {plan.features.map((feature, idx) => (
                        <li key={idx} className="relative pl-6">
                          <i className={`uil uil-${feature.included ? 'check' : 'times'} absolute left-0 w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex items-center justify-center ${feature.included ? 'bg-[#dce7f9] text-[#3f78e0]' : 'bg-[#fae3e4] text-[#e2626b]'}`}></i>
                          <span>{feature.description}</span>
                        </li>
                      ))}
                    </ul>
                    <a href="#" className="btn btn-primary text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] !rounded-[50rem] hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Choose Plan</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
