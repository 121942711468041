import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

export default function Main() {

  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
   
      <div className="grow shrink-0">
      <Header />

      {/* <div style={{position: "fixed", overflow: "auto", height: "calc(100vh - 80px)",backgroundColor: "red", marginTop: "50px"}}>
      <Outlet />
      </div> */}
      <Outlet />
      </div>
      <Footer />
     

    </React.Fragment>
  )
}