import React from 'react'

import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'













export const GoodsServiceTax = () => {



    return (
        <>
            <Section1
                title="Goods and Service Tax"
                subtitle={"Goods and Service Tax (GST) is a comprehensive, multi-stage, destination-based tax that is levied on every value addition in the production and distribution chain."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Goods and Service Tax" 
            Paragraphs={[
              "India is considered to be an upcoming location for business ventures. With the number of young entrepreneurs and established business persons going all out to make a handsome profit from their endeavors across the length and breadth of the country, complying with the plethora of taxes to be paid becomes difficult as there are numerous service taxes to contend with direct as well as indirect. Dealing with all taxation requirements completely and to every one’s satisfaction is a tall order though.",
              "Thankfully, the scenario is set to change completely come July 2017 with almost every single tax including VAT, excise duties as well as special custom levied duties about to be abolished once and for all and replaced by a single taxation system namely the ‘Goods and Services Tax’ known as GST."
            ]}
            />
            <WithoutPoints
            Title="Indirect Taxes & GST" 
            Paragraphs={[
              "However, GST is not confined to merely bringing all sorts of service taxations under one umbrella, it is also about the manner in which the taxes get to be imposed. The businesspersons have to remain aware about the exact points when the excise duty, VAT, CST is added to the price of goods or products. This makes the system ridiculously complex for both the buyer as well as the seller to understand and implement correctly. GST is supposed to simplify the process with the end users being able to benefit from the transparency as well as the pricing in a big way."
            ]}
            />
            <WithoutPoints
            Title="When will GST be imposed on your supplies?" 
            Paragraphs={[
              "The ‘GST Act’ advises the implementation of GST at the point of ‘Supply.’ While the term is meant for selling of goods and/or services predominantly, it also includes lease, rentals, barter, and transfer to an agent or a dealer along with a branch of your company. The GST should be paid by you during purchase of the goods and you will be entitled to add the tax on your products/goods during supply. However, it would be wise to check whether your business transaction has been included in the list of supplies, otherwise GST would not be levied."
            ]}
            />


            <WithoutPoints
            Title="Types" 
            Paragraphs={[
              "The trademarks are beneficial for both consumers as well as businesses. The businesses can create an identity of their own with the trademarks. They can also develop a market reputation with their customers and focus on the growth of their enterprises. The trademarks allow and influence the prospective customers to make decisions on purchasing when it comes to buy the specific products that they are looking to have. Looking for favorite brands becomes a lot easier when there are distinct trademarks to identify with them. It also helps to steer clear of brands that are otherwise not all that reliable."
            ]}
            />
            <WithPoints 
            Responsibilities={[
                "Although the supplies made to countries out of India will not be taxed with GST, you still need to register for making the supplies.",
                "Both Central (CGST) as well as State taxes (SGST) will be levied on Intra state sales.",
                "IGST is applicable for interstate sales that amount to the total sum of CGST and SGST plus charges on imports."
            ]}
            /> 

            <WithPoints 
            Title="Do you need to register for GST?"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "It is mandatory to register, if your annual turnover exceeds Rs 20 lakhs or Rs 10 lakhs(in case of North Eastern States).",
                "Registration is obligatory for making inter-state supplies regardless of your turnover.",
                "Online supplies via a website require GST registration.",
                " If you happen to be an ‘Input Service Distributor’ receiving billing for services rendered to your branches at diverse locations, you would have to register your business for GST"
            ]}
            /> 
            
            
            <WithoutPoints
            Title="You Need to Know about GST" 
            Paragraphs={[
              "All the persons who are liable to register under Schedule V of CGST act should register if their Gross receipts/ Turnover exceed 20 lacs/10 lacs as the case may be in the Financial Year. For register, every person should Submit Form GST REG-01 and the further information Obtained from respective forms and Registration certificate is issued in Form GST REG-06.",
              "For Migration All the existing tax payers will get provisional User ID and Password, with those credentials existing tax payer can create account and upload the required documents he will get GST migration Acknowledgement after successful upload of documents with the digital signature."
            ]}
            />
            
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
