import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo11.png";


export default function Header() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    // Check on initial render
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="relative wrapper bg-soft-primary !bg-[#edf2fc]">
      <nav className="navbar navbar-expand-lg center-nav transparent navbar-light">
        <div className="container xl:flex-row lg:flex-row !flex-nowrap items-center">
          <div className="navbar-brand w-full">
            <a href="/" >
              <img srcSet="./assets/img/logo11.png 1.7x" alt="logo" />
            </a>
          </div>
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            {/* <div className="offcanvas-header xl:hidden lg:hidden flex items-center justify-between flex-row p-6">
              <h3 className="text-white xl:text-[1.5rem] !text-[calc(1.275rem_+_0.3vw)] !mb-0">Sandbox</h3>
              <button type="button" className="btn-close btn-close-white mr-[-0.75rem] m-0 p-0 leading-none text-[#343f52] transition-all duration-[0.2s] ease-in-out border-0 motion-reduce:transition-none before:text-[1.05rem] before:content-['\ed3b'] before:w-[1.8rem] before:h-[1.8rem] before:leading-[1.8rem] before:shadow-none before:transition-[background] before:duration-[0.2s] before:ease-in-out before:flex before:justify-center before:items-center before:m-0 before:p-0 before:rounded-[100%] hover:no-underline bg-inherit before:bg-[rgba(255,255,255,.08)] before:font-Unicons hover:before:bg-[rgba(0,0,0,.11)] focus:outline-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div> */}
            <div className="offcanvas-body xl:!ml-auto lg:!ml-auto flex  flex-col !h-full">
              <ul className="navbar-nav">
                {/* <li className="nav-item dropdown dropdown-mega">
                  <a className="nav-link dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Demos</a>
                  <ul className="dropdown-menu mega-menu mega-menu-dark mega-menu-img">
                    <li className="mega-menu-content mega-menu-scroll">


                      <span className="hidden xl:flex lg:flex"><i className="uil uil-direction before:content-['\ea93']"></i><strong>Scroll to view more</strong></span>
                    </li>

                  </ul>

                </li> */}
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Pages</a>
                  <ul className="dropdown-menu">
                    <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Services</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./services.html">Services I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="/service">Services II</a></li>
                      </ul>
                    </li>
                    <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>About</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./about.html">About I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./about2.html">About II</a></li>
                      </ul>
                    </li>
                    <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Shop</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./shop.html">Shop I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./shop2.html">Shop II</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./shop-product.html">Product Page</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./shop-cart.html">Shopping Cart</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./shop-checkout.html">Checkout</a></li>
                      </ul>
                    </li>
                    <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Contact</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./contact.html">Contact I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./contact2.html">Contact II</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./contact3.html">Contact III</a></li>
                      </ul>
                    </li>
                    <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Career</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./career.html">Job Listing I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./career2.html">Job Listing II</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./career-job.html">Job Description</a></li>
                      </ul>
                    </li>
                    <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Utility</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./404.html">404 Not Found</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./page-loader.html">Page Loader</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./signin.html">Sign In I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./signin2.html">Sign In II</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./signup.html">Sign Up I</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./signup2.html">Sign Up II</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./terms.html">Terms</a></li>
                      </ul>
                    </li>
                    <li className="nav-item"><a className="dropdown-item" href="./pricing.html">Pricing</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="./onepage.html">One Page</a></li>
                  </ul>
                </li> */}

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle={isMobileView ? "dropdown" : ""}>Start A Business</a>
                  <ul className="dropdown-menu">
                    <li className="nav-item"><a className="dropdown-item" href="/privateLimitedCompany">Private Limited Company</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/limitedLiabilityPartnership">Limited Liability Partnership</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/onePersonCompany">One Person Company</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/proprietor">Proprietor</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/partnership">Partnership</a></li>
                    {/* <li className="dropdown dropdown-submenu dropend"><a className="dropdown-item dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Blog Posts</a>
                      <ul className="dropdown-menu">
                        <li className="nav-item"><a className="dropdown-item" href="./blog-post.html">Post without Sidebar</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./blog-post2.html">Post with Sidebar</a></li>
                        <li className="nav-item"><a className="dropdown-item" href="./blog-post3.html">Post with Left Sidebar</a></li>
                      </ul>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle={isMobileView ? "dropdown" : ""}>Trademark & Patent</a>
                  <ul className="dropdown-menu">
                    <li className="nav-item"><a className="dropdown-item" href="/trademarkRegistration">Trademark Registration</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/copyrightRegistration">Copyright Registration</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/designRegistration">Design Registration</a></li>
                    <li className="nav-item"><a className="dropdown-item" href="/patentRegistration">Patent Registration</a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle={isMobileView ? "dropdown" : ""}>Tax Registration</a>
                  <div className="dropdown-menu dropdown-lg">
                    <div className="dropdown-lg-content">
                      <div>
                        <h6 className="dropdown-header">Tax Registration</h6>
                        <ul className="pl-0 list-none">
                          <li><a className="dropdown-item" href="/goodsServiceTax">Goods and Services Tax</a></li>
                          <li><a className="dropdown-item" href="/vatTinCst">VAT / TIN / CST</a></li>
                          <li><a className="dropdown-item" href="/serviceTaxRegistration">Service Tax</a></li>
                          <li><a className="dropdown-item" href="/professionalTax">Professional Tax</a></li>
                        </ul>
                      </div>

                      <div>
                        <h6 className="dropdown-header">Government Registration</h6>
                        <ul className="pl-0 list-none">
                          <li><a className="dropdown-item" href="/foodSafety">Food Safety and Standards</a></li>
                          <li><a className="dropdown-item" href="/importExport">Importer Exporter Code</a></li>
                          <li><a className="dropdown-item" href="/ssi">SSI / MSME</a></li>
                          <li><a className="dropdown-item" href="/shopRegistration">Shop and Establishment</a></li>
                        </ul>
                      </div>

                    </div>

                  </div>
                </li>

                {/* <li className="nav-item dropdown dropdown-mega">
                  <a className="nav-link dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Blocks</a>
                  <ul className="dropdown-menu mega-menu mega-menu-dark mega-menu-img">
                    <li className="mega-menu-content">
                    </li>

                  </ul>

                </li> */}
                {/* <li className="nav-item dropdown dropdown-mega">
                  <a className="nav-link dropdown-toggle" href="#"    data-bs-toggle={isMobileView ? "dropdown" : ""}>Documentation</a>
                  <ul className="dropdown-menu mega-menu">
                    <li className="mega-menu-content">
                      <div className="flex flex-wrap mx-0 xl:mx-[-7.5px] lg:mx-[-7.5px]">
                        <div className="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] max-w-full">
                          <h6 className="dropdown-header">Usage</h6>
                          <ul className="pl-0 list-none  xl:columns-2 lg:columns-2  xl:pb-1 lg:pb-1">
                            <li className="xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/index.html">Get Started</a></li>
                            <li className="xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/forms.html">Forms</a></li>
                            <li className="xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/faq.html">FAQ</a></li>
                            <li className="xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/changelog.html">Changelog</a></li>
                            <li className="xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/credits.html">Credits</a></li>
                          </ul>
                          <h6 className="dropdown-header xl:!mt-6 lg:!mt-6">Styleguide</h6>
                          <ul className="pl-0 list-none  xl:columns-2 lg:columns-2 ">
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/colors.html">Colors</a></li>
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/fonts.html">Fonts</a></li>
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/icons-svg.html">SVG Icons</a></li>
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/icons-font.html">Font Icons</a></li>
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/illustrations.html">Illustrations</a></li>
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/backgrounds.html">Backgrounds</a></li>
                            <li className=" xl:inline-block xl:w-full lg:inline-block lg:w-full"><a className="dropdown-item" href="./docs/styleguide/misc.html">Misc</a></li>
                          </ul>
                        </div>

                        <div className="xl:w-8/12 lg:w-8/12 w-full flex-[0_0_auto] max-w-full xl:border-l-[rgba(164,174,198,0.2)] xl:border-l xl:border-solid lg:border-l-[rgba(164,174,198,0.2)] lg:border-l lg:border-solid">
                          <h6 className="dropdown-header">Elements</h6>
                          <ul className="pl-0 list-none  xl:columns-3 lg:columns-3 ">
                            <li><a className="dropdown-item" href="./docs/elements/accordion.html">Accordion</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/alerts.html">Alerts</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/animations.html">Animations</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/avatars.html">Avatars</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/background.html">Background</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/badges.html">Badges</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/buttons.html">Buttons</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/card.html">Card</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/carousel.html">Carousel</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/dividers.html">Dividers</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/form-elements.html">Form Elements</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/image-hover.html">Image Hover</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/image-mask.html">Image Mask</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/lightbox.html">Lightbox</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/player.html">Media Player</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/modal.html">Modal</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/pagination.html">Pagination</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/progressbar.html">Progressbar</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/shadows.html">Shadows</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/shapes.html">Shapes</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/tables.html">Tables</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/tabs.html">Tabs</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/text-animations.html">Text Animations</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/text-highlight.html">Text Highlight</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/tiles.html">Tiles</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/tooltips-popovers.html">Tooltips & Popovers</a></li>
                            <li><a className="dropdown-item" href="./docs/elements/typography.html">Typography</a></li>
                          </ul>
                        </div>

                      </div>

                    </li>

                  </ul>

                </li> */}

                <li className="nav-item dropdown dropdown-mega">
                  <a className="nav-link" href="/contact" >Contact</a>
                </li>
              </ul>

              <div className="offcanvas-footer xl:hidden lg:hidden">
                <div>
                  <a href="mailto:first.last@email.com" className="link-inverse">info@email.com</a>
                  <br /> 00 (123) 456 78 90 <br />
                  <nav className="nav social social-white mt-4">
                    <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-twitter before:content-['\ed59'] !text-white text-[1rem]"></i></a>
                    <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] !text-white text-[1rem]"></i></a>
                    <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] !text-white text-[1rem]"></i></a>
                    <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-instagram before:content-['\eb9c'] !text-white text-[1rem]"></i></a>
                    <a className="text-[#cacaca] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 motion-reduce:transition-none hover:translate-y-[-0.15rem] m-[0_.7rem_0_0]" href="#"><i className="uil uil-youtube before:content-['\edb5'] !text-white text-[1rem]"></i></a>
                  </nav>
                </div>
              </div>

            </div>

          </div>

          {/* <div className="navbar-other w-full !flex !ml-auto"> */}
          <div className="navbar-other !flex !ml-auto">
            <ul className="navbar-nav !flex-row !items-center !ml-auto">
              {/* <li className="nav-item dropdown language-select uppercase group">
                <a className="nav-link dropdown-item dropdown-toggle" href="#" role="button"    data-bs-toggle={isMobileView ? "dropdown" : ""} aria-haspopup="true" aria-expanded="false">En</a>
                <ul className="dropdown-menu group-hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">
                  <li className="nav-item"><a className="dropdown-item hover:text-[#3f78e0] hover:bg-[inherit] focus:text-[#3f78e0] focus:bg-[inherit]" href="#">En</a></li>
                  <li className="nav-item"><a className="dropdown-item hover:text-[#3f78e0] hover:bg-[inherit] focus:text-[#3f78e0] focus:bg-[inherit]" href="#">De</a></li>
                  <li className="nav-item"><a className="dropdown-item hover:text-[#3f78e0] hover:bg-[inherit] focus:text-[#3f78e0] focus:bg-[inherit]" href="#">Es</a></li>
                </ul>
              </li> */}
              {/* <li className="nav-item hidden xl:block lg:block md:block">
                <a href="/contact" className="btn btn-sm btn-primary text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] !rounded-[50rem] hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Contact</a>
              </li> */}
              <li className="nav-item xl:hidden lg:hidden">
                <button className="hamburger offcanvas-nav-btn"><span></span></button>
              </li>
            </ul>

          </div>

        </div>

      </nav>

    </header>
  )
}