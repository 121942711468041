import React from 'react'

import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'





export const VatTinCst = () => {
 


    return (
        <>
            <Section1
                title="VAT / TIN / CST"
                subtitle={"VAT, TIN, and CST are tax mechanisms applied to the sale and transfer of goods, ensuring tax compliance and revenue generation across states and businesses."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="VAT / TIN / CST Registration" 
            Paragraphs={[
              "VAT Registration is applicable to Dealers of Product/goods; every dealer needs to register himself for Sale of any product in a particular state. VAT Registration norms are different for each state based on VAT law enacted in particular state. For Ex., for Karnataka state KVAT ACT 2003 is applicable in this act every dealer whose turnover exceeds 7.5 lakes per annum (i.e., 62500 p.m.) should get himself register under Karnataka commercial Taxes department and he needs submit application in both electronically and physically.",
              "If the dealer has operations in more than one state he should get CST registration also in order to pay tax at 2 %.If dealer has any interstate purchases he needs to submit C-Form in order t o avail the concessional rate of 2%."
            ]}
            />
            
            <WithPoints 
            Title="Documents required:"
            Description="For Individuals/Proprietor businesses:"
            Responsibilities={[
                "PAN Card of proprietor",
                "4 pass size photos of proprietor",
                "Bank statement of proprietor",
                "NOC for registered office owner",
                "Rental agreement and electricity bill of office address"
            ]}
            /> 
            <WithPoints 
            // Title="Documents required:"
            Description="For Companies:"
            Responsibilities={[
                "Certificate of Incorporation",
                "Bank statement of company",
                "PAN Card of company",
                "4 pass size photos director",
                "Rental agreement and electricity bill of office address.",
                "NOC for registered office from owner.",
                "MOA/AOA"
            ]}
            /> 
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
