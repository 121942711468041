import React from 'react'
import { useState } from 'react'
import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'


export const ProfessionalTax = () => {


    return (
        <>
            <Section1
                title="Professional Tax"
                subtitle={"Professional Tax is a state-level tax levied on professions, trades, and employment, ensuring that professionals contribute to state revenue as per regulatory norms."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Professional Tax" 
            Paragraphs={[
              "Profession tax (PT) is levied by state legislature Ex., in Karnataka PT is levied under (the Karnataka Tax on Professions, Trades, Callings and Employments Act, 1976). Every Person who is engaged in profession, Carrying any trade with his professional Knowledge or He has been appointed as mentioned in schedule to the Act.",
              "If any person having the salary of more than Rs. 10000/- should pay professional tax. The Employer is responsible to deduct PT from his salary and pay the amount state government within 20 days from the end of the month if PT payable is less than 5000 per month then the employer may opt to pay Professional Tax on quarterly basis.",
              "All the employers should get certificate of registration from profession tax officer of applicable jurisdiction"
            ]}
            />
            {/* <WithoutPoints
            Title="Indirect Taxes & GST" 
            Paragraphs={[
              "However, GST is not confined to merely bringing all sorts of service taxations under one umbrella, it is also about the manner in which the taxes get to be imposed. The businesspersons have to remain aware about the exact points when the excise duty, VAT, CST is added to the price of goods or products. This makes the system ridiculously complex for both the buyer as well as the seller to understand and implement correctly. GST is supposed to simplify the process with the end users being able to benefit from the transparency as well as the pricing in a big way."
            ]}
            /> */}
            {/* <WithoutPoints
            Title="When will GST be imposed on your supplies?" 
            Paragraphs={[
              "The ‘GST Act’ advises the implementation of GST at the point of ‘Supply.’ While the term is meant for selling of goods and/or services predominantly, it also includes lease, rentals, barter, and transfer to an agent or a dealer along with a branch of your company. The GST should be paid by you during purchase of the goods and you will be entitled to add the tax on your products/goods during supply. However, it would be wise to check whether your business transaction has been included in the list of supplies, otherwise GST would not be levied."
            ]}
            /> */}


            {/* <WithoutPoints
            Title="Types" 
            Paragraphs={[
              "The trademarks are beneficial for both consumers as well as businesses. The businesses can create an identity of their own with the trademarks. They can also develop a market reputation with their customers and focus on the growth of their enterprises. The trademarks allow and influence the prospective customers to make decisions on purchasing when it comes to buy the specific products that they are looking to have. Looking for favorite brands becomes a lot easier when there are distinct trademarks to identify with them. It also helps to steer clear of brands that are otherwise not all that reliable."
            ]}
            /> */}
            {/* <WithPoints 
            Responsibilities={[
                "Although the supplies made to countries out of India will not be taxed with GST, you still need to register for making the supplies.",
                "Both Central (CGST) as well as State taxes (SGST) will be levied on Intra state sales.",
                "IGST is applicable for interstate sales that amount to the total sum of CGST and SGST plus charges on imports."
            ]}
            />  */}

            <WithPoints 
            Title="Karnataka Professional Tax Registration in Bangalore"
            Description="Professional tax is a tax that is levied by some of the state governments in India. In Karnataka, it is referred to as the Karnataka Tax on Profession, Trade, Callings and Employment Act, 1976. In short it is also referred to as the PT Act. It is imposed primarily on the income that is earned by people who live and work in a state.
            Professionals like chartered accountants, doctors, engineers, architectures, consultants, lawyers and other professionals are supposed to be in the ambit of this Act as well. And this tax is only imposed by state government. Apart from Karnataka the following states also levy this tax:"
            Responsibilities={[
                "Maharashtra",
                "Andhra Pradesh",
                "Kerala",
                "Tamil Nadu",
                "Gujarat",
                "West Bengal",
                "Assam",
                "Chhattisgarh",
                "Meghalaya",
                "Orissa",
                "Tripura",
                "Madhya Pradesh",
                "Bihar",
                "Telangana",
                "Sikkim",
            ]}
            /> 
            <WithPoints 
            Title="Who has to Pay Professional Tax?"
            Description="Every individual who is working in any profession or job in Karnataka is supposed to pay professional tax. The same is also applicable for people who are engaged in some trade or the other or is in possession of some appointment or the other. Meant this tax is pertinent for employees and employer. It could be a government job or a private one. It does not really matter in these cases. As far as employers are concerned the following entities need to pay the tax:"
            Responsibilities={[
                "Sole proprietor",
                "Corporations and corporate bodies",
                "Partnership firm (registered / unregistered)",
                "Hindu undivided family (HUF)",
                "Limited company (private / public)",
                "Trust / society / club / association",
                "Limited liability partnership"
            ]}
            /> 
            
            
            <WithoutPoints
            Title="What are Enrollment and Registration Certificates? Who Needs Them?" 
            Paragraphs={[
              "As per rules and regulations of PT registration in Bangalore; an employer needs to get two kinds of registration in this case – i.e. a) certificate of enrollment (EC) and b) certificate of registration (RC). This however depends on whether an employer has people working in her or his company or not. EC is needed for the employers and RC is needed for the employees."
            ]}
            />
            <WithoutPoints
            Title="Who is has to Deduct the Tax and Submit It to the Government?" 
            Paragraphs={[
              "As far as self employed individuals are concerned, it is the duty of individual who has to pay the tax. In case of salaried employees, it is the responsibility of the employee."
            ]}
            />

            <WithPoints 
            Title="Documents Needed for PT Registration in Bangalore"
            Description="The following documents are required to register for professional tax in Bangalore:"
            Responsibilities={[
                "Address proof of establishment",
                "Salary details of the employees",
                "Particulars of employees",
                "Address of business owners such as partners, director, or proprietor",
                "Financial statements of the business",
                "PAN (Permanent Account Number) of the business owners",
                "Address proof and certificate of incorporation of the company",
                "Photos of business owners"
            ]}
            /> 
            <WithPoints 
            Title="Professional Tax Registration Process"
            Description="If you wish to obtain professional tax registration in Bangalore you can apply it online since it would be easier for you to do complete swiftly. Following are the steps that you have to follow in this particular regard:"
            Responsibilities={[
                "Professional tax registration enrolment",
                "E-payment",
                "Printing the enrolment application",
                "Getting the enrolment certificate",
                "Getting the acknowledgment of enrolment",
                "Professional tax return e-filing"
            ]}
            /> 
            <WithPoints 
            Title="Compliance under Professional Tax"
            Description="As per rules and regulations in this regard, there are a few things that have to be kept in mind:"
            Responsibilities={[
                "The first step is that an employer has to pay the yearly taxes that are applicable for the employers.",
                "If you have an EC, you need to pay the tax every year prior to 30th April according to specified rates.",
                "Ensure that you are deducting monthly taxes for your employees and submitting them to the government.",
                "If an employer has an RC, they must file a return within 20 days after the previous month showing wages, salaries, and deducted taxes.",
                "File the returns and pay the taxes electronically.",
                "Late tax payment or filing incurs a penalty of INR 250.",
                "Employers must also file a yearly tax return within 60 days after the previous year, detailing wages, salaries, and deducted taxes."
            ]}
            /> 
            
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
