import React from 'react';

export const WithoutPoints = ({ Title, Paragraphs }) => {
  return (
    <section className="wrapper !bg-[#ffffff]">
      <div className="container !pb-[0.5rem] xl:pb-10 lg:pb-10 md:pb-10 ">
        <div className="flex flex-wrap ">
          <div className=" w-full flex-[0_0_auto]  max-w-full !mx-auto">
            <div className="blog single mt-[2rem]">
                <div className="card-body flex-[1_1_auto] ">
                  <h2 className="h1 !mb-3 !leading-[1.3]">{Title}</h2>
                  {Paragraphs.map((paragraph, index) => (
                    <p key={index} className="mb-2">{paragraph}</p>
                  ))}
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


{/* <WithoutPoints 
  Title="Dynamic Title Example" 
  Paragraphs={[
    "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet.",
    "Donec sed odio dui consectetur adipiscing elit. Etiam adipiscing tincidunt elit, eu convallis felis suscipit ut.",
    "Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.",
  ]}
/> */}