import React from 'react';
import { WithPoints } from '../components/Paragraph/withPoints';

const Accordion = ({ items }) => {
    return (
        <div className="wrapper !bg-[#ffffff]">
            <div className="container !pb-[0.5rem] xl:!pb-2 lg:!pb-2 md:!pb-2 ">
                <div className="accordion accordion-wrapper" id="accordionExample">
                    {items.map((item, index) => (
                        <div className="card plain accordion-item" key={index}>
                            <div className="card-header !mb-0 !p-[0_0_.8rem_0] !border-0 !bg-inherit" id={`heading${index}`}>
                                <h3 className="h2 !mb-3 !mt-9 !leading-[1.35]">{item.heading}</h3>
                                <button
                                    className={`accordion-button !text-[0.9rem] before:!text-[#fab758] hover:!text-[#fab758] ${index === 0 ? '' : 'collapsed'}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={index === 0 ? 'true' : 'false'}
                                    aria-controls={`collapse${index}`}
                                >
                                    {item.title}
                                </button>
                            </div>

                            <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body !p-[0_0_0_1.1rem]">
                                <p>{item.content}</p>
                                    {item.Responsibilities ? (
                                        <WithPoints
                                            Title={item.Title}
                                            Description={item.Description}
                                            Responsibilities={item.Responsibilities}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
