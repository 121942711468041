import React from 'react';

export const Section3 = ({
  backgroundColor = '#edf2fc',
  title = 'Our Process',
  subtitle = 'Find out everything you need to know about creating a business process model',
  videoPoster,
  videoSources = [],
  processSteps = []
}) => {
  return (
    <section className={`wrapper !bg-[${backgroundColor}]`}>
      <div className="container py-[4.5rem] md:pt-24 lg:pt-0 xl:pt-0 xl:pb-24 lg:pb-24 md:pb-24">
        <div className="flex flex-wrap mx-[-15px] !text-center">
          <div className="xl:w-10/12 lg:w-10/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
            <div className="lg:mt-[-10rem] xl:!mt-[-15rem] !relative">
              <div
                className="shape bg-dot red rellax !w-[6rem] !h-[8rem] absolute opacity-50 bg-[radial-gradient(#e2626b_2px,transparent_2.5px)]"
                data-rellax-speed="1"
                style={{ top: '1rem', left: '-3.9rem' }}
              ></div>
              <div
                className="shape !rounded-[50%] bg-line primary rellax !w-[8rem] !h-[8rem] absolute opacity-50"
                data-rellax-speed="1"
                style={{ bottom: '2rem', right: '-3rem' }}
              ></div>
              <video
                poster={videoPoster}
                className="player relative z-[2] rounded-[0.4rem]"
                playsInline
                controls
                preload="none"
              >
                {videoSources.map((source, index) => (
                  <source key={index} src={source.src} type={source.type} />
                ))}
              </video>
            </div>
          </div>
          {/* Process Header */}
          <div className="flex flex-wrap mx-[-15px] !text-center">
            <div className="xl:w-9/12 lg:w-9/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
              <h2 className="!text-[.75rem] uppercase text-[#aab0bc] mb-3 mt-[3.5rem] tracking-[0.02rem]">{title}</h2>
              <h3 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] !leading-[1.3] !mb-0 !text-center xl:!px-10 xxl:!px-20">
                {subtitle}
              </h3>
              <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] process-wrapper !text-center mt-9">
                {processSteps.map((step, index) => (
                  <div key={index} className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] max-w-full">
                    <img
                      src={step.icon}
                      className={`svg-inject icon-svg mb-3 !w-[2.6rem] !h-[2.6rem] m-[0_auto] text-[${step.iconColor}]`}
                      alt={step.title}
                    />
                    <h3 className="!mb-1 ">{`${index + 1}. ${step.title}`}</h3>
                    <p>{step.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
