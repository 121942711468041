import React from 'react'
import { useState } from 'react'
import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'




export const ServiceTaxRegistration = () => {


    return (
        <>
            <Section1
                title="Service Tax Registration"
                subtitle={"Service Tax Registration is essential for businesses offering taxable services in India, ensuring compliance and the ability to collect and remit service tax."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Service Tax Registration" 
            Paragraphs={[
              "Service tax is indirect tax levied by central government and administered by CBEC, as per Service tax act 1994 ever service provider whose gross receipts exceeds 9 lacs should register with CBEC by submitting ST-1 .The service provider should pay service tax on monthly basis or Quarterly basis as the case may by if once the Taxable service receipts exceed 10 lacs during the financial year.",
              "The CBEC will give Service tax registration certificate in Form ST-2 along with service tax registration No. This number needs to be quote on all service invoices raised by the service provider.",
              "Service tax registration is mandatory for those, who need pay service tax in reverse charge mechanism/Joint charge mechanism, those who need to distribute input credit."
            ]}
            />
            
            <WithPoints 
            Title="Documents required:"
            Description="For Individuals/Proprietor businesses:"
            Responsibilities={[
                "PAN Card of proprietor",
                "4 pass size photos of proprietor",
                "Bank statement of proprietor",
                "NOC for registered office owner",
                "Rental agreement and electricity bill of office address"
            ]}
            /> 
            <WithPoints 
            // Title="Documents required:"
            Description="For Companies:"
            Responsibilities={[
                "Certificate of Incorporation",
                "Bank statement of company",
                "PAN Card of company",
                "4 pass size photos director",
                "Rental agreement and electricity bill of office address.",
                "NOC for registered office from owner.",
                "MOA/AOA"
            ]}
            /> 
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
