import React from 'react'
import { useState } from 'react'
import { Section1 } from '../components/services/Section1'
import { Section2 } from '../components/services/Section2'
import { Section3 } from '../components/services/Section3'
import { Section4 } from '../components/services/Section4'
import { Section5 } from '../components/services/Section5'
import { Section6 } from '../components/services/Section6'
import { Section7 } from '../components/services/Section7'
import serviceimg from   "../assets/img/service.jpg"



const cards = [
    {
      title: '24/7 Support',
      description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta.',
      icon: './assets/img/icons/lineal/telephone-3.svg',
      iconColor: 'text-[#fab758]',
      bgColor: '#fef3e4',
    },
    {
      title: 'Secure Payments',
      description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta.',
      icon: './assets/img/icons/lineal/shield.svg',
      iconColor: 'text-[#e2626b]',
      bgColor: '#fae6e7',
    },
    {
      title: 'Daily Updates',
      description: 'Nulla vitae elit libero, a pharetra augue.',
      icon: './assets/img/icons/lineal/cloud-computing-3.svg',
      iconColor: 'text-[#7cb798]',
      bgColor: '#eaf3ef',
    },
    {
      title: 'Market Research',
      description: 'Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget.',
      icon: './assets/img/icons/lineal/analytics.svg',
      iconColor: 'text-[#3f78e0]',
      bgColor: '#e0e9fa',
    },
  ];
  


  //section 3
  const videoSources = [
    { src: './assets/media/movie.mp4', type: 'video/mp4' },
    { src: './assets/media/movie.webm', type: 'video/webm' }
  ];


  const processSteps = [
    {
      icon: './assets/img/icons/lineal/light-bulb.svg',
      iconColor: '#3f78e0',
      title: 'Concept',
      description: 'Nulla vitae elit libero elit non porta gravida eget metus cras.'
    },
    {
      icon: './assets/img/icons/lineal/settings-3.svg',
      iconColor: '#e2626b',
      title: 'Prepare',
      description: 'Nulla vitae elit libero elit non porta gravida eget metus cras.'
    },
    {
      icon: './assets/img/icons/lineal/design.svg',
      iconColor: '#7cb798',
      title: 'Retouch',
      description: 'Nulla vitae elit libero elit non porta gravida eget metus cras.'
    }
  ];

  //section 5
  const sectionData = {
    subTitle: 'Our Pricing',
    title: 'We offer great prices, premium products, and quality service for your business.',
};


//section 6
const pricingData = [
    {
      title: 'Basic Plan',
      icon: './assets/img/icons/basic-plan.svg',
      priceMonthly: '19.99',
      priceYearly: '199.99',
      features: [
        { description: 'Feature 1', included: true },
        { description: 'Feature 2', included: false },
      ],
    },
    {
      title: 'Pro Plan',
      icon: './assets/img/icons/pro-plan.svg',
      priceMonthly: '39.99',
      priceYearly: '399.99',
      features: [
        { description: 'Feature 1', included: true },
        { description: 'Feature 2', included: true },
      ],
    },
    {
      title: 'Premium Plan',
      icon: './assets/img/icons/premium-plan.svg',
      priceMonthly: '59.99',
      priceYearly: '599.99',
      features: [
        { description: 'Feature 1', included: true },
        { description: 'Feature 2', included: true },
      ],
    },
  ];



export const Service = () => {
    const [isYearly, setIsYearly] = useState(false);

    const switchToMonthly = () => setIsYearly(false);
    const switchToYearly = () => setIsYearly(true);
  
    
    return (
        <>
            <Section1 
            title="Services"
            subtitle={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod."}
            backgroundImage={serviceimg}/>
       <Section2
        heading="What We Do?"
        subheading="The service we offer is specifically designed to meet your needs."
        description="Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas sed diam eget risus varius blandit sit amet non magna. Maecenas faucibus mollis interdum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et."
        cards={cards}
        link={{ href: '#', text: 'More Details' }}
      />
            <Section3
        backgroundColor="#edf2fc"
        title="Our Process"
        subtitle="Find out everything you need to know about creating a business process model"
        videoPoster="./assets/img/photos/movie.jpg"
        videoSources={videoSources}
        processSteps={processSteps}
      />
            <Section4
  sections={[
    {
      question: 'What is your return policy?',
      answer: 'Our return policy allows for returns within 30 days of purchase. Items must be in their original condition with tags attached.',
    },
    {
      question: 'How can I track my order?',
      answer: 'You can track your order by visiting our order tracking page and entering your order number. You will receive an email with your tracking number when your order ships.',
    },
    {
      question: 'Do you offer international shipping?',
      answer: 'Yes, we offer international shipping to most countries. Shipping rates and delivery times vary depending on the destination.',
    },
    // Add more questions and answers as needed
  ]}/>
  
  <Section5 sectionData={sectionData} />
  <Section6
        pricingData={pricingData}
        switchToMonthly={switchToMonthly}
        switchToYearly={switchToYearly}
      />
      <Section7 
  sectionTitle="Join Our Community" 
  description="We are trusted by over 5000+ clients. Join them now and grow your business." 
  stats={[
    { value: '7518', label: 'Completed Projects' },
    { value: '5472', label: 'Satisfied Customers' },
    { value: '2184', label: 'Expert Employees' },
  ]}
/>

 </>
    )
}
