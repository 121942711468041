import React from "react";
import { Landing } from "../pages/Landing";
import { Service } from "../pages/Service";
import { Contact } from "../pages/Contact";
import { About } from "../pages/About";
import { Page404 } from "../pages/Page404";
import { TrademarkRegistration } from "../pages/Trademark & Patent/TrademarkRegistration";
import { CopyrightRegistration } from "../pages/Trademark & Patent/CopyrightRegistration";
import { DesignRegistration } from "../pages/Trademark & Patent/DesignRegistration";
import { PatentRegistration } from "../pages/Trademark & Patent/PatentRegistration";
import { GoodsServiceTax } from "../pages/Tax Registration/GoodsServiceTax";
import { VatTinCst } from "../pages/Tax Registration/VatTinCst";
import { ServiceTaxRegistration } from "../pages/Tax Registration/ServiceTaxRegistration";
import { ProfessionalTax } from "../pages/Tax Registration/ProfessionalTax";
import { FoodSafety } from "../pages/Government Registration/FoodSafety";
import { ImportExport } from "../pages/Government Registration/ImportExport";
import { SSI } from "../pages/Government Registration/SSI";
import { ShopRegistration } from "../pages/Government Registration/ShopRegistration";
import { LimitedLiabilityPartnership } from "../pages/startABusiness/LimitedLiabilityPartnership";
import { OnePersonCompany } from "../pages/startABusiness/OnePersonCompany";
import { Partnership } from "../pages/startABusiness/Partnership";
import { PrivateLimitedCompany } from "../pages/startABusiness/PrivateLimitedCompany";
import { Proprietor } from "../pages/startABusiness/Proprietor";

const publicRoutes = [
  { path: "/", element: <Landing /> },
  { path: "/service", element: <Service /> },
  { path: "/contact", element: <Contact /> },
  { path: "/aboutUs", element: <About /> },
  { path: "/trademarkRegistration", element: <TrademarkRegistration /> },
  { path: "/copyrightRegistration", element: <CopyrightRegistration /> },
  { path: "/designRegistration", element: <DesignRegistration /> },
  { path: "/patentRegistration", element: <PatentRegistration /> },
  { path: "/goodsServiceTax", element: <GoodsServiceTax /> },
  { path: "/vatTinCst", element: <VatTinCst /> },
  { path: "/serviceTaxRegistration", element: <ServiceTaxRegistration /> },
  { path: "/professionalTax", element: <ProfessionalTax /> },
  { path: "/foodSafety", element: <FoodSafety /> },
  { path: "/importExport", element: <ImportExport /> },
  { path: "/ssi", element: <SSI /> },
  { path: "/shopRegistration", element: <ShopRegistration /> },
  { path: "*", element: <Page404 /> },
  { path: "/limitedLiabilityPartnership", element: <LimitedLiabilityPartnership /> },
  { path: "/onePersonCompany", element: <OnePersonCompany /> },
  { path: "/partnership", element: <Partnership /> },
  { path: "/PrivateLimitedCompany", element: <PrivateLimitedCompany /> },
  { path: "/Proprietor", element: <Proprietor /> },
];

export default publicRoutes;