import React from 'react'
import { useState } from 'react'
import { Section1 } from '../../components/services/Section1'
import { Section2 } from '../../components/services/Section2'
import { Section3 } from '../../components/services/Section3'
import { Section4 } from '../../components/services/Section4'
import { Section5 } from '../../components/services/Section5'
import { Section6 } from '../../components/services/Section6'
import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'
import Accordion from '../../components/accordion'






export const ImportExport = () => {


    return (
        <>
            <Section1
                title="Importer Exporter Code (IEC)"
                subtitle={"An Importer Exporter Code (IEC) is a unique identification number required for any business or individual engaged in the import or export of goods and services in India."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Importer Exporter Code (IEC)" 
            Paragraphs={[
              "Every Person who is involved in international Trade should get IE Code (10 digit number Granted by Directorate General of Foreign Trade) to do Import or /and Export. No person can do import/export without IE Code."
            ]}
            />
              <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />
        </>
    )
}
