import React from 'react'

import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'
import Accordion from '../../components/accordion'




const accordionItems = [
    {
      heading: 'Frequently Asked Questions (FAQs) on Trademark Registration',
      title: 'What Is Trademark?',
      Title: 'There are some specific legal requirements for registering a trademark, such as:',
        content: 'The Trademark or brand name functions as a type of visual symbol that is immediately associated with the identity of a brand. This can be a name, a word signature, a combination of colors, numerals or anything else that can be used by a company that offers a specific type of goods or services and/or any other type of product that should be differentiated from similar products offered by other names in the industry.',
        // Description: 'Here are some responsibilities for the role:',
        Responsibilities: [
            'The chosen mark must be one that can be represented graphically (i.e. in paper form).',
            'The trademark should be unique and it should be able to distinguish the products/ services of one company from those that are made by others.',
            'The trademark should be used to identify the goods and services that are presented by a specific company; so that both parties are clear on the fact that items are associated with a specific brand.'
        ],
    },
    {
      title: 'How To Choose A Trade Mark Name?',
      Title: 'Select a trademark name following the principles:',
        Responsibilities: [
            'In case a word is used as a trademark, it must be easy to remember, speak and spell.',
            'Ideally, the finest trademarks are uniquely coined words or invented words.',
            'Avoid from using a geographical name, thus no one can have any monopoly on it.',
            'Do not use words with a laudatory implication like perfect or great or super etc.',
            'Make sure that you perform a survey to make sure that the same or similar mark has not already been used in the market.'
        ],
    },
    {
      title: 'What Is The Technical Function Of A Trade Mark?',
      Title: 'A trademark mainly performs 4 distinct functions under the aegis of business practices of the modern era:',
        // content: 'The Trademark or brand name functions as a type of visual symbol that is immediately associated with the identity of a brand. This can be a name, a word signature, a combination of colors, numerals or anything else that can be used by a company that offers a specific type of goods or services and/or any other type of product that should be differentiated from similar products offered by other names in the industry.',
        // Description: 'Here are some responsibilities for the role:',
        Responsibilities: [
            'It helps to identify the goods and/or services as well as their origins.',
            'It helps to advertise and promote the goods/services.',
            'It ensures its authentic and unchanged quality.',
            'It helps to create a distinct image for the goods and/or services.'
        ],
    },
    {
      title: 'Who Are Eligible To Apply For A Trademark And How?',
        content: 'Anyone who is running a business legally claims to be the main proprietor (assignee) of a specific trademark, can choose to apply for the registration of the trademark. It is important to note that the application must include the trademark, the name logo and address details of the application, the power of attorney, the nature of the goods and services as well as the period of use for the trademark and the signature. The application needs to be filed in the proper office and it should be carried out in Hindi or English when it is done for India.',
    },
    {
      title: 'How Can One Apply For A Trademark With Respect To A Particular Type Of Goods Or Services?',
        content: 'In accordance with Trade Marks Act, 1999, the items and services are classified according to the rules and parameters of International Classification of the goods and services. In this Act, the Schedule IV presents with a summary of items that fall in the different categories and classes, but it is important to remember that it is mainly indicative. The Trade Marks Registrar authority has the power to determine the appropriate trademark class to be assigned for specific types of goods and services in the time of product’s final class determination. If you are looking to have more information on Schedule IV of Trade Marks Act and its intricate aspects, you should consult the International Classification Body that has been published by World Intellectual Property Organization (WIPO). You can also contact the Act’s local office for further help on the matter.',
    },
    {
        title: 'What Are The Various Kinds Of Trademarks Available For Adoption?',
        Title: 'The various kinds of trademarks that are available for adoption are:',
          Responsibilities: [
            'Any name such as the personal name or surname of an applicant or the predecessor in business, even the signature of a person, can be chosen as a mark.',
            'Any kind of invented word or arbitrary dictionary word/words that is not directly descriptive or illustrative of the product or service quality.',
            'Numerals and/or letters in any combination to form a word or phrase.',
            'The proprietorship right of the trademark can be obtained through registration under the Trade Mark Act or in relation to specific goods or services.',
            'Monograms.',
            'Devices, including various fancy symbols or devices.',
            'The shape of goods and/or their packaging.',
            'Combination of various colors or a single color with some device or word.',
            'Marks that create a 3D sign.',
            'Sound marks represented in standard notation or described in words and graphically represented.'
          ],
      },
        {
        title: 'What Is The Purpose Of Trade Mark System?',
        Title: 'Here are the few reasons that trademark system serves:',
          Responsibilities: [
            'The trademark system helps to identify the main physical origin of the goods and services. Its brand is the essential seal of authenticity.',
            'It helps to encourage further purchase.',
            'It provides assurance as to the identity of the products and services and their origin.',
            'It serves to function as a symbol of affiliation and loyalty.',
            'It can enable the consumer to create effectively a fashion or a lifestyle statement.',
          ],
      },
      {
        title: 'Who Can Benefit From The Use Of A Trade Mark?',
        Title: 'Here are some points those can get benefit from a trademark:',
        Responsibilities: [
            'The Registered Proprietor - The Registered Proprietor of the trademark has the authority to prevent other traders from using his company’s trademark unlawfully, sue for damages as well as ensure the destruction of infringing labels and/or goods.',
            'The Legal professionals - The Trade Marks Registration system is operated by professionals such as legal attorneys, para-legal advisors, or agents. They act for their clients and help to process the trademark application.',
            'The Government - The Trade Marks Registry can generate a huge amount of revenue every year and it is expected to increase the revenue year over year.',
            'The consumers – The people who purchase the trademarked goods and services.'
        ]
    },
    {
        title: 'What Are The Essential Advantages Of Registering A Trademark?',
          content: 'Through the registration of the trademark, the owner of the mark obtains complete and exclusive right for being able to use the mark and the symbol R for representing the goods and/or services for which the trademark has been generated. It also allows the owner to seek infringement reliefs in the country’s courts. However, it is important to note that the exclusive right for using the trademark is subjected to the conditions associated with it and entered during registration such as limitations in terms of area of usage. In some cases where similar trademarks are used by two or more individuals due to some kind of special circumstances, such exclusive rights tend not to work against each other.',
      },
      {
        title: 'Who Can Benefit From The Use Of A Trade Mark?',
        Title: 'Here are some points those can get benefit from a trademark:',
        Responsibilities: [
            'The Registered Proprietor - The Registered Proprietor of the trademark has the authority to prevent other traders from using his company’s trademark unlawfully, sue for damages as well as ensure the destruction of infringing labels and/or goods.',
            'The Legal professionals - The Trade Marks Registration system is operated by professionals such as legal attorneys, para-legal advisors, or agents. They act for their clients and help to process the trademark application.',
            'The Government - The Trade Marks Registry can generate a huge amount of revenue every year and it is expected to increase the revenue year over year.',
            'The consumers – The people who purchase the trademarked goods and services.'
        ]
    },
    {
        title: 'What Are The Sources Associated With Trademark Laws?',
        Responsibilities: [
            'The national statute i.e., Trade Marks Act, 1999 and the rules thereunder.',
            'Regional treaty.',
            'National bilateral treaty.',
            'Office practice and rulings.',
            'Decisions of the relevant courts.',
            'International multilateral convention.',
            'Decisions of the Intellectual Property Appellate Board.',
            'Textbooks written by professional experts and academicians.',
        ],
    },
    {
        title: 'What Is Contained In The Register Of The Trade Mark?',
          content: 'At present, the register of Trade Mark is maintained in the electronic form. It contains the trademark name and the class of the goods and services for which the registration is being carried out. The various details that affect the scope of the registration process are also mentioned in there. Other details including address of the relevant proprietors, the various details of trade as well as descriptive details of the proprietor, the date of application of the convention if it is applicable. If the trade mark is registered by taking the consent of the proprietor of some previous trade mark, then that information should also be included in the register.',
      },
    {
        title: 'Can Corrections Be Carried Out In The Application Or Register?',
          content: 'Yes. However, it is important to keep in mind that the main trade mark that has been applied for must not be majorly altered which in fact can affect its identity. Any changes made to this are permissible based on the rules that are detailed in subordinate legislation.',
      },
    {
        title: 'Is It Possible To Remove A Registered Trademark From The Register?',
          content: 'Yes. The registered trademark can be removed from the register on the grounds of prescribed Form, in case the mark is deemed to be wrongly present on the register. It is also possible for the Registrar to sue motto issue Notice for the removal of the registered trade mark.',
      },
    
  ];



export const TrademarkRegistration = () => {
   


    return (
        <>
            <Section1
                title="Trademark Registration"
                subtitle={"Trademark registration grants legal protection to your brand, ensuring exclusive rights to use and defend it against unauthorized use by others."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="What Is Trademark?" 
            Paragraphs={[
              "A TRADEMARK is a feature that is associated with a company or business entity that offers some specific types of goods and services. The purpose of the trademark is to distinguish it from other brands within the same segment so that it can be easily identified. The trademark can be anything, for instance, it can be a word, a design, a phrase, a symbol or even a combination of words, symbols, phrases or designs. A service mark is similar to a trademark; the only difference is that it is used for distinguishing and identifying the company that offers a specific form of service. A trademark can appear on the item offered by the company or the packaging used for it. The service mark is normally seen on the adverts used for promoting the services."
            ]}
            />

            <WithoutPoints
            Title="Trademark Search: What is it?" 
            Paragraphs={[
              "Trademark Search is an essential process that should be carried out prior to filling the trademark application. It is to be ensuring that the new trademark is not similar to any registered trademark that is already in existence. Sometimes the registration for a similar trademark may also have been submitted. For this reason, it is always a good idea to perform a thorough trademark search into the relevant trademark classes for your goods and/or services."
            ]}
            />
            <WithoutPoints
            Title="Advantages/ Benefits of Trademark Registration for Your Business" 
            Paragraphs={[
              "The trademarks are beneficial for both consumers as well as businesses. The businesses can create an identity of their own with the trademarks. They can also develop a market reputation with their customers and focus on the growth of their enterprises. The trademarks allow and influence the prospective customers to make decisions on purchasing when it comes to buy the specific products that they are looking to have. Looking for favorite brands becomes a lot easier when there are distinct trademarks to identify with them. It also helps to steer clear of brands that are otherwise not all that reliable."
            ]}
            />

            <WithPoints 
            // Title="Job Responsibilities"
            Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Create brand awareness",
                "Build your brand image",
                "Avoid duplication of business brand",
                "Develop the intellectual properties as vital assets",
                "Protect goodwill of brand name",
                "Recognition to quality product and trustworthiness",
                "Enjoy the legal ownership over the business brands",
                "Authority to take legal action against the infringement",
                "Use of TM and R symbol is a prestige of your brand or company",
            ]}
            /> 

            <WithoutPoints
            Paragraphs={[
              "Your trademark is an integral part of your company and brand which means that it cannot be used by any other company. In the event of someone else using your trademark, you are free to take serious legal steps against them."
            ]}
            />

            <WithoutPoints
            Title="Trademark Registration Process" 
            Paragraphs={[
              "STEP–1: Firstly, you need to carry out a detailed search through the Trade Marks Registry and its records in order to see that the trademark that you want to use for your business is available and has not been used for similar products and services.",
              "STEP–2: If there are conflicting results with the Search Report, then you need to present with opinion on the trademark.",
              "STEP–3: Once there is a positive search result, you will have to get Application for Registration of Trademark prepared with the Form of Authorization. The documents will be set up on the stamp paper for authorizing the Firm for acting on the behalf in order to fill up and prosecute the trademark details. Once you sign on the application, it is going to be presented to Trade Mark Registry. As the application is processed, you will get an Application number that can help you use the TM mark.",
            ]}
            />

            <Accordion
            items={accordionItems}
            />
            
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
