import React from 'react'
import { Section7 } from '../components/services/Section7'

export const About = () => {
  return (
    <>

      <section className="wrapper  bg-[rgba(246,247,249,1)] ">
        <div className="container pt-10 xl:pt-[4.5rem] lg:pt-[4.5rem] md:pt-[4.5rem] !text-center">
          <div className="flex flex-wrap mx-[-15px]">
            <div className="xl:w-6/12 flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
              <h1 className="text-[calc(1.365rem_+_1.38vw)] font-bold leading-[1.2] xl:text-[2.4rem] mb-4">Hello! We are <br /> <span className='text-[#c22f32]'>Patson Legal</span></h1>
              <h2 className="text-[1rem] tracking-[0.02rem] uppercase text-[#aab0bc] !mb-3 !leading-[1.35]">Most Trusted and Experienced Team in BANGALORE <br /> Served more than 1500 Entrepreneurs</h2>
              {/* <h2 className="lead text-[1.05rem] !leading-[1.6] font-medium !mb-0">Most Trusted and Experienced Team in BANGALORE <br /> Served more than 1500 Entrepreneurs</h2> */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
        {/* <figure className="absoute" style={{ bottom: "0", left: " 0", zIndex: "2" }}><img src="./assets/img/service.jpg" alt="image" /></figure> */}
      </section>
      {/* /section */}
      <section className="wrapper !bg-[#ffffff]  angled upper-end lower-end relative border-0 before:top-[-4rem] before:border-l-transparent before:border-r-[100vw] before:border-t-[4rem] before:border-[#fefefe] before:content-[''] before:block before:absolute before:z-0 before:!border-y-transparent before:border-0 before:border-solid before:right-0 after:bottom-[-4rem] after:border-l-transparent after:border-r-[100vw] after:border-b-[4rem] after:border-[#fefefe] after:content-[''] after:block after:absolute after:z-0 after:!border-y-transparent after:border-0 after:border-solid after:right-0">
        <div className="container pt-6 ">
          <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] !mb-[4.5rem] xl:!mb-[7rem] lg:!mb-[7rem] md:!mb-[7rem] items-center">
            <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full !relative xl:!order-2 lg:!order-2">
              <div className="shape bg-dot primary bg-[radial-gradient(#3f78e0_2px,transparent_2.5px)] rellax !w-[6rem] !h-[10rem] absolute z-[1] opacity-50" data-rellax-speed="1" style={{ top: "3rem", left: "5.5rem" }} ></div>
              <div className="flex flex-wrap !relative overlap-grid-2">
                <div className="item xl:w-full xl:z-[3] xl:ml-[30%] xl:mt-0 lg:w-full lg:z-[3] lg:ml-[30%] lg:mt-0 md:w-full md:z-[3] md:ml-[30%] md:mt-0">
                  <figure className="!rounded-[.4rem] relative"><img className="!rounded-[.4rem] " src="./assets/img/svg/justice-animate.svg" srcset="./assets/img/svg/justice-animate.svg 2x" alt="image" /></figure>
                </div>
                {/* <div className="item xl:w-[55%] xl:mt-[-45%] xl:z-[4] xl:ml-0 lg:w-[55%] lg:mt-[-45%] lg:z-[4] lg:ml-0 md:w-[55%] md:mt-[-45%] md:z-[4] md:ml-0">
                  <figure className="!rounded-[.4rem] shadow-[0_0_1.25rem_rgba(30,34,40,0.04)] relative"><img className="!rounded-[.4rem] " src="./assets/img/about_patson.jpg" srcset="./assets/img/photos/about3@2x.jpg 2x" alt="image" /></figure>
                </div> */}
              </div>
            </div>
            {/*/column */}
            <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <img src="./assets/img/icons/lineal/megaphone.svg" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] mb-4" alt="image" />
              <h2 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] leading-[1.3] !mb-3">Who Are We?</h2>
              <p className="!mb-5">Founded by young, dynamic and well qualified professionals, Patson legal knowledge process pvt. Ltd. provides their professional services to private and public like New company Registration, Secretarial services, Chartered accountant services Tax registration, Filing of various taxes and Intellectual Property Rights in India and Worldwide.</p>
              <p className="!mb-6">Patson is a specialized team of, Chartered accountant, company Secretary, Trademark Agents, Patent attorneys, Corporate Lawyers and supporting staff. Our diversified team with extensive legal and corporate knowledge enables us to cater to the stated and unstated needs of our clients . It will be our pleasure to assist everyone and provide professional advice related to IP SERVICE. We are Specializes in Patent, trademark registration and design as well as copyright. It also includes counseling, prosecution, licensing, and litigation. We also represent clients on IP issues related to international trade, portfolio management, the Internet, e-commerce and domain name.</p>
              {/* <div className="flex flex-wrap mx-[-15px] mt-[-15px] xl:mx-[-20px]">
                <div className="xl:w-6/12 w-full flex-[0_0_auto] mt-[15px] xl:px-[20px] px-[15px] max-w-full">
                  <ul className="pl-0 list-none bullet-bg bullet-soft-primary  !mb-0">
                    <li className="relative pl-6"><span><i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Aenean eu leo quam ornare curabitur blandit tempus.</span></li>
                    <li className="relative pl-6 mt-3"><span><i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Nullam quis risus eget urna mollis ornare donec elit.</span></li>
                  </ul>
                </div>
              
                <div className="xl:w-6/12 w-full flex-[0_0_auto] mt-[15px] xl:px-[20px] px-[15px] max-w-full">
                  <ul className="pl-0 list-none bullet-bg bullet-soft-primary  !mb-0">
                    <li className="relative pl-6"><span><i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Etiam porta sem malesuada magna mollis euismod.</span></li>
                    <li className="relative pl-6 mt-3"><span><i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell absolute left-0"></i></span><span>Fermentum massa vivamus faucibus amet euismod.</span></li>
                  </ul>
                </div>
                
              </div> */}
              {/*/.row */}
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
          {/* /section */}



          {/*/.row */}
        </div>


        <Section7
          sectionTitle="Join Our Community"
          description="We are trusted by over 6000+ clients. Join them now and grow your business."
          stats={[
            { value: '15+', label: 'Years in Business' },
            { value: '810', label: 'Projects Completed' },
            { value: '50+', label: 'Team Members' },
          ]}
        />

        {/* <section className="wrapper !bg-[#edf2fc] aboutUs-bg">
          <div className="container py-[4.5rem] xl:!py-2 lg:!py-2 md:!py-2">
            <div className="flex flex-wrap mx-[-15px] mb-10">
              <div className="xl:w-10/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
                <div className="flex flex-wrap mx-[-15px] items-center counter-wrapper mt-[-30px] !text-center">
                  <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                    <img src="./assets/img/icons/lineal/check.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                    <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">6000</h3>
                    <p className=" text-[0.8rem] font-medium mb-0">Clients</p>
                  </div>
             
                  <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                    <img src="./assets/img/icons/lineal/user.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                    <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">7</h3>
                    <p className=" text-[0.8rem] font-medium mb-0">Years in Business</p>
                  </div>
                 
                  <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                    <img src="./assets/img/icons/lineal/briefcase-2.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                    <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">810</h3>
                    <p className=" text-[0.8rem] font-medium mb-0">Projects</p>
                  </div>
            
                  <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                    <img src="./assets/img/icons/lineal/award-2.svg" className="svg-inject icon-svg icon-svg-lg text-[#3f78e0] !mb-3 !w-12 !h-12 m-[0_auto]" alt="image" />
                    <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none mb-2">34</h3>
                    <p className=" text-[0.8rem] font-medium mb-0">Strong Team</p>
                  </div>
         
                </div>
          
              </div>
      
            </div>

          </div>

        </section> */}


        <section className="wrapper !bg-[#ffffff]  angled upper-end lower-end relative border-0 before:top-[-4rem] before:border-l-transparent before:border-r-[100vw] before:border-t-[4rem] before:border-[#fefefe] before:content-[''] before:block before:absolute before:z-0 before:!border-y-transparent before:border-0 before:border-solid before:right-0 after:bottom-[-4rem] after:border-l-transparent after:border-r-[100vw] after:border-b-[4rem] after:border-[#fefefe] after:content-[''] after:block after:absolute after:z-0 after:!border-y-transparent after:border-0 after:border-solid after:right-0">
          <div className="container ">

            {/* /section */}
            <div className="flex flex-wrap mx-[-15px] mb-5">
              <div className="md:w-10/12 lg:w-10/12 xl:w-8/12 xxl:w-7/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto !text-center">
                <img src="../assets/img/icons/lineal/list.svg" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] mb-4 m-[0_auto]" alt="image" />
                <h2 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] !leading-[1.3] mb-4 xl:!px-[4.5rem] lg:!px-[4.5rem]">Here are 4 working steps to organize our business projects.</h2>
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
            <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-50px] items-center">
              <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full xl:!order-2 lg:!order-2">
                <div className="card xl:!mr-6 lg:!mr-6">
                  <div className="card-body p-6">
                    <div className="flex flex-row">
                      <div>
                        <span className="icon btn btn-circle btn-lg btn-soft-primary pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">01</span></span>
                      </div>
                      <div>
                        <h4 className="!mb-1">Intellectual Property</h4>
                        <p className="!mb-0">We are Specializes in Patent, trademark registration and design as well as copyright. It also includes counseling, prosecution, licensing, and litigation.</p>
                      </div>
                    </div>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
                <div className="card mt-6 xl:!ml-16 lg:!ml-16">
                  <div className="card-body p-6">
                    <div className="flex flex-row">
                      <div>
                        <span className="icon btn btn-circle btn-lg btn-soft-primary pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">02</span></span>
                      </div>
                      <div>
                        <h4 className="!mb-1">Chartered Accountants</h4>
                        <p className="!mb-0">We have been rendering a multitude of services under one roof over the past 7 years, especially in the field of back office management services, audit and assurance.</p>
                      </div>
                    </div>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
                <div className="card mt-6 xl:mx-6 lg:mx-6">
                  <div className="card-body p-6">
                    <div className="flex flex-row">
                      <div>
                        <span className="icon btn btn-circle btn-lg btn-soft-primary pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">03</span></span>
                      </div>
                      <div>
                        <h4 className="!mb-1">Company Secretaries</h4>
                        <p className="!mb-0">Professionally managed Practising Company Secretaries company in bangalore, India.we provides wide range of consultancy services to various medium and large sized corporates.</p>
                      </div>
                    </div>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
                <div className="card xl:!mr-6 lg:!mr-6 mt-6">
                  <div className="card-body p-6">
                    <div className="flex flex-row">
                      <div>
                        <span className="icon btn btn-circle btn-lg btn-soft-primary pointer-events-none !mr-4 xl:text-[1.3rem] !w-12 !h-12 text-[calc(1.255rem_+_0.06vw)] inline-flex items-center justify-center leading-none !p-0 !rounded-[100%]"><span className="number table-cell text-center align-middle text-[1.1rem] font-bold m-[0_auto]">04</span></span>
                      </div>
                      <div>
                        <h4 className="!mb-1">Patent</h4>
                        <p className="!mb-0">Our patent attorneys & consultants have advanced degrees in cutting-edge scientific disciplines.</p>
                      </div>
                    </div>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}

              </div>
              {/*/column */}
              <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
                <h2 className="text-[calc(1.265rem_+_0.18vw)] font-bold xl:text-[1.4rem] leading-[1.35] !mb-3">Why Choose Us?</h2>
                <p className="!mb-6">Patson is a distinguished team composed of chartered accountants, company secretaries, trademark agents, patent attorneys, corporate lawyers, and a skilled support staff, all of whom bring specialized knowledge to address a wide array of legal and corporate requirements. Our multidisciplinary team is equipped with extensive expertise across legal, financial, and regulatory domains, enabling us to effectively respond to both explicit and implicit needs of our clients. With a collaborative approach, we combine diverse skill sets and perspectives to provide well-rounded, comprehensive support, ensuring that clients benefit from a holistic approach to legal and corporate challenges. Each member of our team is committed to a client-centric philosophy, consistently prioritizing tailored service that recognizes the unique circumstances of every client we serve.</p>
                <p>Patson’s motto, “small enough to care, large enough to cope,” underscores our dedication to providing personalized service without compromising on our capacity to handle complex, large-scale assignments. We maintain the agility and responsiveness typical of smaller firms, allowing us to build close relationships with clients and give them the focused attention they deserve. At the same time, we possess the resources and expertise to manage larger projects with efficiency and precision, making us a trusted partner for clients seeking customized solutions in an increasingly complex regulatory landscape. This balance of personalization and capability is at the core of Patson’s reputation for reliability, making us a preferred choice for clients who value both expertise and a proactive approach to legal and corporate services.</p>
                {/* <p className="!mb-6">Nullam id dolor id nibh ultricies vehicula ut id elit. Vestibulum id ligula porta felis euismod semper. Aenean lacinia bibendum nulla sed consectetur. Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis.</p> */}
                {/* <a href="#" className="btn btn-primary text-white !bg-[#3f78e0] border-[#3f78e0] hover:text-white hover:bg-[#3f78e0] hover:border-[#3f78e0] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#3f78e0] active:border-[#3f78e0] disabled:text-white disabled:bg-[#3f78e0] disabled:border-[#3f78e0] !rounded-[50rem] !mb-0 hover:translate-y-[-0.15rem] hover:shadow-[0_0.25rem_0.75rem_rgba(30,34,40,0.15)]">Learn More</a> */}
              </div>
              {/*/column */}
            </div>
          </div>
        </section>
        {/* /.container */}
      </section>
      {/* /section */}
      {/* <section className="wrapper !bg-[#edf2fc]">
        <div className="container pt-24 pb-[4.5rem] xl:pb-0 lg:pb-0 md:pb-0">
          <div className="flex flex-wrap mx-[-15px] lg:mx-[-20px] xl:mx-0 items-center">
            <div className="md:w-5/12 lg:w-5/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] lg:px-[20px] xl:px-0 max-w-full xl:!ml-[8.33333333%] xsm:!hidden sm:!hidden md:!hidden xl:!flex lg:!flex !relative !self-end">
              <div className="shape !rounded-[50%] !bg-[#e0e9fa] rellax !w-[12.5rem] !h-[12.5rem] hidden xl:block lg:block z-[1] absolute" data-rellax-speed="1" style={{ top: "7rem", left: "1rem" }}></div>
              <figure className=" relative z-[2]"><img className="w-full max-w-full !h-auto" src="./assets/img/photos/co1.png" srcset="./assets/img/photos/co1@2x.png 2x" alt="image" /></figure>
            </div>
       
            <div className="md:w-7/12 lg:w-6/12 xl:w-6/12 xxl:w-5/12 w-full flex-[0_0_auto] px-[15px] lg:px-[20px] xl:px-0 max-w-full xl:!ml-[8.33333333%]">
              <div className="swiper-container dots-start dots-closer xl:!mt-10 lg:!mt-10 md:!mt-10 xl:!mb-20 lg:!mb-20 md:!mb-20 relative !z-10" data-margin="30" data-dots="true">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <blockquote className="icon relative p-0 border-0 text-[1rem] leading-[1.7] font-medium m-[0_0_1rem] before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio consectetur nulla dapibus curabitur blandit.”</p>
                        <div className="flex items-center text-left">
                          <div className="info !pl-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Coriss Ambady</h5>
                            <p className="!mb-0 text-[0.8rem]">Financial Analyst</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                 
                    <div className="swiper-slide">
                      <blockquote className="icon relative p-0 border-0 text-[1rem] leading-[1.7] font-medium m-[0_0_1rem] before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio consectetur adipiscing dapibus curabitur blandit.”</p>
                        <div className="flex items-center text-left">
                          <div className="info !pl-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Cory Zamora</h5>
                            <p className="!mb-0 text-[0.8rem]">Marketing Specialist</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                 
                    <div className="swiper-slide">
                      <blockquote className="icon relative p-0 border-0 text-[1rem] leading-[1.7] font-medium m-[0_0_1rem] before:content-['\201d'] before:absolute before:top-[-1.5rem] before:left-[-0.9rem] before:text-[rgba(52,63,82,0.05)] before:text-[10rem] before:leading-none before:z-[1]">
                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio consectetur adipiscing dapibus curabitur blandit.”</p>
                        <div className="flex items-center text-left">
                          <div className="info !pl-0">
                            <h5 className="!mb-1 text-[.95rem] !leading-[1.5]">Nikolas Brooten</h5>
                            <p className="!mb-0 text-[0.8rem]">Sales Manager</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                 
                  </div>
               
                </div>
            
              </div>
            
            </div>
      
          </div>
   
        </div>
     
      </section> */}
      {/* /section */}
      {/* <section className="wrapper !bg-[#ffffff]">
        <div className="container py-[4.5rem] xl:!py-24 lg:!py-24 md:!py-24">
          <div className="flex flex-wrap mx-[-15px] !mb-3">
            <div className="md:w-10/12 lg:w-10/12 xl:w-9/12 xxl:w-7/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto !text-center">
              <img src="./assets/img/icons/lineal/team.svg" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] mb-4 m-[0_auto]" alt="image" />
              <h2 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] leading-[1.3] mb-3 xl:!px-[4.5rem] lg:!px-[4.5rem]">Save your time and money by choosing our professional team.</h2>
            </div>
   
          </div>
    
          <div className="!relative">
            <div className="shape !rounded-[50%] !bg-[#fff8ee] rellax !w-[6rem] !h-[6rem] absolute z-[1]" data-rellax-speed="1" style={{ bottom: " 0.5rem", right: "-1.7rem" }}></div>
            <div className="shape !rounded-[50%] bg-line red rellax !w-[6rem] !h-[6rem] absolute z-[1] opacity-50" data-rellax-speed="1" style={{ top: "0.5rem", left: "-1.7rem" }}></div>
            <div className="swiper-container dots-closer !mb-6" data-margin="0" data-dots="true" data-items-xxl="4" data-items-xl="3" data-items-lg="3" data-items-md="2" data-items-xs="1">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="item-inner">
                      <div className="card">
                        <div className="card-body p-[40px]">
                          <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatars/te1.jpg" srcset="./assets/img/avatars/te1@2x.jpg 2x" alt="image" />
                          <h4 className="mb-1">Coriss Ambady</h4>
                          <div className="text-[0.65rem] mb-2 uppercase tracking-[0.02rem] font-bold text-[#aab0bc]">Financial Analyst</div>
                          <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                          <nav className="nav social !mb-0">
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                          </nav>
                         
                        </div>
                     
                      </div>
               
                    </div>
               
                  </div>
                  
                  <div className="swiper-slide">
                    <div className="item-inner">
                      <div className="card">
                        <div className="card-body p-[40px]">
                          <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatars/te2.jpg" srcset="./assets/img/avatars/te2@2x.jpg 2x" alt="image" />
                          <h4 className="mb-1">Cory Zamora</h4>
                          <div className="text-[0.65rem] mb-2 uppercase tracking-[0.02rem] font-bold text-[#aab0bc]">Marketing Specialist</div>
                          <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                          <nav className="nav social !mb-0">
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                          </nav>
                  
                        </div>
            
                      </div>
              
                    </div>
               
                  </div>
          
                  <div className="swiper-slide">
                    <div className="item-inner">
                      <div className="card">
                        <div className="card-body p-[40px]">
                          <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatars/te3.jpg" srcset="./assets/img/avatars/te3@2x.jpg 2x" alt="image" />
                          <h4 className="mb-1">Nikolas Brooten</h4>
                          <div className="text-[0.65rem] mb-2 uppercase tracking-[0.02rem] font-bold text-[#aab0bc]">Sales Manager</div>
                          <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                          <nav className="nav social !mb-0">
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                          </nav>
             
                        </div>
                  
                      </div>
               
                    </div>
                  
                  </div>
               
                  <div className="swiper-slide">
                    <div className="item-inner">
                      <div className="card">
                        <div className="card-body p-[40px]">
                          <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatars/te4.jpg" srcset="./assets/img/avatars/te4@2x.jpg 2x" alt="image" />
                          <h4 className="mb-1">Jackie Sanders</h4>
                          <div className="text-[0.65rem] mb-2 uppercase tracking-[0.02rem] font-bold text-[#aab0bc]">Investment Planner</div>
                          <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                          <nav className="nav social !mb-0">
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                          </nav>
               
                        </div>
                     
                      </div>
                    
                    </div>
         
                  </div>
            
                  <div className="swiper-slide">
                    <div className="item-inner">
                      <div className="card">
                        <div className="card-body p-[40px]">
                          <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatars/te5.jpg" srcset="./assets/img/avatars/te5@2x.jpg 2x" alt="image" />
                          <h4 className="mb-1">Laura Widerski</h4>
                          <div className="text-[0.65rem] mb-2 uppercase tracking-[0.02rem] font-bold text-[#aab0bc]">Sales Specialist</div>
                          <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                          <nav className="nav social !mb-0">
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                          </nav>
               
                        </div>
              
                      </div>
           
                    </div>
                
                  </div>
        
                  <div className="swiper-slide">
                    <div className="item-inner">
                      <div className="card">
                        <div className="card-body p-[40px]">
                          <img className="rounded-[50%] !w-[5rem] mb-4" src="./assets/img/avatars/te6.jpg" srcset="./assets/img/avatars/te6@2x.jpg 2x" alt="image" />
                          <h4 className="mb-1">Tina Geller</h4>
                          <div className="text-[0.65rem] mb-2 uppercase tracking-[0.02rem] font-bold text-[#aab0bc]">Financial Analyst</div>
                          <p className="!mb-2">Fermentum massa justo sit amet risus morbi leo.</p>
                          <nav className="nav social !mb-0">
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-twitter before:content-['\ed59'] text-[1rem] text-[#5daed5]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-facebook-f before:content-['\eae2'] text-[1rem] text-[#4470cf]"></i></a>
                            <a className="m-[0_.7rem_0_0] text-[1rem] transition-all duration-[0.2s] ease-in-out translate-y-0 hover:translate-y-[-0.15rem]" href="#"><i className="uil uil-dribbble before:content-['\eaa2'] text-[1rem] text-[#e94d88]"></i></a>
                          </nav>
                       
                        </div>
                 
                      </div>
                  
                    </div>
              
                  </div>
               
                </div>
               
              </div>
      
            </div>
          
          </div>
     
        </div>

      </section> */}

      <section className="wrapper !bg-[#ffffff]  angled upper-end lower-end relative border-0 before:top-[-4rem] before:border-l-transparent before:border-r-[100vw] before:border-t-[4rem] before:border-[#fefefe] before:content-[''] before:block before:absolute before:z-0 before:!border-y-transparent before:border-0 before:border-solid before:right-0 after:bottom-[-4rem] after:border-l-transparent after:border-r-[100vw] after:border-b-[4rem] after:border-[#fefefe] after:content-[''] after:block after:absolute after:z-0 after:!border-y-transparent after:border-0 after:border-solid after:right-0">
        <div className="container pt-20">
          <div className="flex flex-wrap mx-[-15px] md:mx-[-20px] lg:mx-[-20px] xl:mx-[-35px] mt-[-50px] items-center">
            <div className="md:w-8/12 lg:w-6/12 xl:w-5/12 lg:!ml-0 xl:!ml-[8.33333333%] w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full !relative">
              <div className="shape bg-dot primary rellax !w-[7rem] !h-[12.5rem] !bg-[radial-gradient(#3f78e0_2px,transparent_2.5px)] absolute z-[1] opacity-50" data-rellax-speed="1" style={{ top: "-2rem", left: "-1.4rem" }}></div>
              <figure className="!rounded-[.4rem] relative z-[2]"><img className="!rounded-[.4rem] w-full max-w-full !h-auto" src="./assets/img/svg/question-animate.svg" srcset="./assets/img/photos/about4@2x.jpg 2x" alt="image" /></figure>
            </div>
            {/*/column */}
            <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[50px] max-w-full">
              <img src="./assets/img/icons/lineal/telemarketer.svg" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] mb-4" alt="image" />
              <h2 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] leading-[1.3] !mb-8">Convinced yet? Let's take the next step together.</h2>
              <div className="flex flex-row">
                <div>
                  <div className="icon text-[#3f78e0]  xl:text-[1.4rem] text-[calc(1.265rem_+_0.18vw)] mr-6 mt-[-0.25rem]"> <i className="uil uil-location-pin-alt before:content-['\ebd8']"></i> </div>
                </div>
                <div>
                  <h5 className="!mb-1">Address</h5>
                  <address className=" not-italic leading-[inherit] mb-2">1297, 30th Main Rd, Banashankari Stage II, <br /> Banashankari, Bengaluru, Karnataka 560070 <br className="hidden xl:block lg:block md:block" />India</address>
                </div>
              </div>
              <div className="flex flex-row">
                <div>
                  <div className="icon text-[#3f78e0]  xl:text-[1.4rem] text-[calc(1.265rem_+_0.18vw)] mr-6 mt-[-0.25rem]"> <i className="uil uil-phone-volume before:content-['\ec50']"></i> </div>
                </div>
                <div>
                  <h5 className="!mb-1">Phone</h5>
                  <p>+91 95909 09909</p>
                </div>
              </div>
              <div className="flex flex-row">
                <div>
                  <div className="icon text-[#3f78e0]  xl:text-[1.4rem] text-[calc(1.265rem_+_0.18vw)] mr-6 mt-[-0.25rem]"> <i className="uil uil-envelope before:content-['\eac8']"></i> </div>
                </div>
                <div>
                  <h5 className="!mb-1">E-mail</h5>
                  <p className="!mb-0"><a href="mailto:support@patsonlegal.com" className="text-[#60697b]">support@patsonlegal.com</a></p>
                </div>
              </div>
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
    </>
  )
}
