import React from 'react'

import {Section1} from '../../components/services/Section1'

import {Section7} from '../../components/services/Section7'
import serviceimg from   "../../assets/img/service.jpg"
import { WithPoints} from '../../components/Paragraph/withPoints'
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'





export const OnePersonCompany = () => {

  
    
    return (
        <>
            <Section1 
            title="One Person Company Registration"
            subtitle={"One person company registration allows a single entrepreneur to establish a business with limited liability protection, enabling easier management and compliance for small businesses."}
            backgroundImage={serviceimg}/>

<WithoutPoints
  Title="What is an OPC or ‘One Person Company’ Registration?" 
  Paragraphs={[
    "The idea for an one person company was floated in 2005 by the team of expert headed by Dr. J. J. Irani and incorporated as a law under the Companies Act of 2013. With the law categorically stating that, 'One Person Company means a company which has only one member, ' it offers an ideal opportunity for startup ventures to function as a legal entity.",
    "However, only natural born Indians are entitled to form and register an OPC. No individual can operate more than 5 such businesses at the same time.",
    "It is a relatively novel concept where a single person has the entire control of the business. However, there are no share holders for the business. The liabilities are at a minimum too making it the number one choice of young entrepreneurs who are hoping to gain a foothold in the business world. The owner doubles up as the director here while the second person is a nominee director with absolutely no power except when the director is incapable of functioning properly. Furthermore, the OPC needs to be converted into a ‘Private Limited Company’ if turnover exceeds 50 lakhs or a ‘Public Limited’ if it has an average turnover exceeding 2 crore.",
  ]}/>
  

  <WithPoints 
  Title="Requirements for Registration"
  
  Responsibilities={[
    "A single member (who can be the Director too)",
    "A member who is considered to be a nominee (can act as a director as well)",
    "Must be a natural citizen who is a resident of the country for 182 days at least the year before",
    "Must possess a bank account for the company",
    "Must appoint an auditor for preparing the financial statement and conduct an audit"
  ]}
/>

<WithPoints 
  Title="Advantages of  OPC Registration?"
  
  Responsibilities={[
    "It can continue to exist and does not face issues with death or exodus of other members. The business can go on indefinitely even when the ownership changes hands.",
    "Transference of the company is exceedingly simple as you only have to alter the information pertaining to the sole owner as well as the nominee director without any trouble whatsoever.",
    "Financial Institutions are more willing to fund a one man company instead of a partnership or proprietorship firm.",
    "You are legally entitled to purchase, disburse, and add assets or property in the name of the company. The nominee director does not have any legal claim to it",
    "Does not  have to comply with too many regulations",
    "Minimum paperwork/documentation required",
  ]}
/>

<WithPoints 
  Title="Taxation Rules for OPC"
  
  Responsibilities={[
    "It is mandatory to file Income Tax Returns",
    "TDS to be filled on a quarterly basis mentioning the TAN. Deducting tax at source is compulsory, if the company has employees",
    "Obtaining an ESI registration is mandatory by law, if the OPC employs more than 10 persons",
    "Filing of VAT and/or service tax returns is required for OPC with valid registrations.",
  ]}
/>


<WithPoints 
  Title="Documents required for Director are"
  
  Responsibilities={[
    "Self attested PAN card (2 copies) ( attested by gazetted officer or bank manager)",
    "Self attested Address Proof (Aadhaar card/Driving License/Passport/Voters ID) (2 copies)  (attested by gazetted officer or bank manager)",
    "5 Latest passport size Photographs of proposed Director",
    "Self attested residential Proof (Bank Statement/Telephone Bill)",
    "Rental agreement/ Sale Deed/Tax paid Receipt and utility bill (not older than one month)",
    "NOC"
  ]}
/>
<WithPoints 
  Title="Documents required for Nominee are"
  
  Responsibilities={[
    "Self attested PAN card ",
    "Self attested Address Proof (Aadhaar card/Driving License/Passport/Voters ID) ",
    "Bank Statement -2 months (self attested)",
    "3 Latest passport size Photographs",
    "Rental agreement/ Sale Deed/Tax paid Receipt and utility bill (not older than one month)",
    "NOC"
  ]}
/>



<WithPoints 
  Title="You will get the documents after registration of your company"
  
  Responsibilities={[
    "Digital Signature for 1 Director",
    "Company PAN Card",
    "DIN for 1 Director",
    "Incorporation Certificate",
    "Company TAN/TDS Number",
    "MOA & AOA"
    
  ]}
/>

      <Section7 
  sectionTitle="Join Our Community" 
  description="We are trusted by over 5000+ clients. Join them now and grow your business." 
  stats={[
    { value: '7518', label: 'Completed Projects' },
    { value: '5472', label: 'Satisfied Customers' },
    { value: '2184', label: 'Expert Employees' },
  ]}
/>
 </>
  )
}
