import React from 'react'
import { useState } from 'react'
import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'





export const SSI = () => {
    const [isYearly, setIsYearly] = useState(false);

    const switchToMonthly = () => setIsYearly(false);
    const switchToYearly = () => setIsYearly(true);


    return (
        <>
            <Section1
                title="SSI / MSME"
                subtitle={"Are business classifications based on investment and turnover, offering eligibility for various government benefits and schemes."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Importer Exporter Code (IEC)" 
            Paragraphs={[
              "As per Micro small Medium enterprises development act 2006 MSME’s are classified in to Two Main classes",
                "1. Manufacturing Sector: Enterprises engaged in manufacture or production of goods and eligibility is depends on amount of investment in plant in machinery.",
                "2. Service sector: Enterprises engaged in Services and the eligibility is based on the investment in equipment ."
            ]}
            />

<WithPoints 
            Title="Some Schemes for MSME Enterprises are as follows:"
            Responsibilities={[
                "Credit Link Capital Subsidy Scheme for Technology upgradation provides 15% Subsidy for purchase of plant and machinery.",
                "Credit Guarantee Fund Scheme provides MSME Enterprises with up to 50 Lakhs Collateral free loans.",
                "The ISO 9000 / HACCP / 14001 Certification Reimbursement Scheme has been merged with the ZED certification scheme.",
                "MSME MDA scheme offers up to 75% funding for to and fro airfare of MSME entrepreneurs in overseas trade delegations."
            ]}
            />
              <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />
        </>
    )
}
