import React from 'react'
import { useState } from 'react'
import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'



export const PatentRegistration = () => {
    const [isYearly, setIsYearly] = useState(false);

    const switchToMonthly = () => setIsYearly(false);
    const switchToYearly = () => setIsYearly(true);


    return (
        <>
            <Section1
                title="Patent Registration"
                subtitle={"Patent registration provides legal protection for inventions, ensuring the inventor's exclusive rights to the creation for a set period."}
                backgroundImage={serviceimg} />
            
            {/* <WithoutPoints
            Title="Design Registration" 
            Paragraphs={[
              "A design is regarded to be the intellectual property of its creator and can be protected by law from being copied, counterfeited or reused by rival companies and / or individuals. Any design created exclusively for an industry or process can be registered thus ensuring its protection."
            ]}
            /> */}
            <WithoutPoints
            Title="Patent registration" 
            Paragraphs={[
              "Obtaining private rights over an innovation or a procedure is possible by registering it as a patent. The entire process is overseen by the ‘Controller General of Patents’, ‘Designs & Trade Marks’ at ‘The Patent Office’. The application for registration is checked with the patent registered for a period of 20 years only if the application is in compliance with the 'Patents Act', of 1970 and follows the regulations included within 'The Patents Rules 1972'. It is considered to be a part of Intellectual Property along with Trademarks & Copyrights."
            ]}
            />
            <WithoutPoints
            Title="What is a Patent?" 
            Paragraphs={[
              "A patent is granted by way of legal document that protects an invention with the inventor being able to enjoy special rights related to his invention that includes the right to create, make use of, sell or donate his creation for a fixed number of years in accordance with the 'Patents Act' introduced in 1970.",
              "A patent can also be registered for improvement of a previously patented discovery. The registration not only forbids the imitators from using it themselves but also prevent independent inventors to make and use similar inventions even when they are derived from sources that are not connected to a registered invention. Since the protection sets a rigid barrier for various companies operating within the same industry to compete with each other effectively, the 'The Patents Rules 1972' make it clear that all inventions cannot be patented until they confirm with certain regulations and are judged to be patentable."
            ]}
            />
            <WithoutPoints
            Title="Criteria for being Patented" 
            Paragraphs={[
              "To be considered eligible to be patented, an invention must meet the following criteria:-",
              "It must be totally new.",
              "It must be seen as an improved version over an existing technology bearing enough differences that can make it stand alone with the similarity, if any not being obvious enough to be considered as a modified version of an old patented invention.",
              "The invention needs to be useful and can be beneficial for individuals or companies. However, a majority of legal professionals dealing with patent disputes and registration explain this condition as not being used for illegal or immoral purposes."
            ]}
            />

            <WithPoints 
            Title="What does the 'Patents Act' protect?"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Work related invention",
                "New Manufacturing process",
                "New machines",
                "Software with Hardware Devices",
                "An utility item that had not been used before",
                "The process of manufacturing a drug or medicine",
                "Process of food recipe"
            ]}
            /> 
            <WithPoints 
            Title="What is not patentable?"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Any obvious or slight alterations made to an existing patented machine, manufacture, process or technology.",
                "Inventions that confirm a natural law.",
                "Abstract theories.",
                "Inventions that are illegal, immoral or considered to be harmful to beings and environment.",
                "Using a known process to create a new product.",
                "The admixture of various known components.",
                "Invention of agricultural & horticultural products or process.",
                "Medical treatment or process of treating a patient.",
                "Atomic energy related invention."
            ]}
            /> 
            <WithPoints 
            Title="Advantages"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "It ensures legal protection for an invention for industrial uses.",
                "An Indian registered patent forms the basis for registering the same invention in other countries. The rule is reversed in case of foreign inventions that are patented elsewhere.",
                "Businesses / firms and corporations benefit hugely by registering for patents as it gives them a competitive edge over their close rivals who are forbidden by law to use the same process or product for as long the patent remains valid.",
                "The patent is considered to be an intangible asset and the organization or individual remain free to sell it or franchise it and even contract it for commercial purpose.",
                "An user is entitled to earn royalty by licensing his product."
            ]}
            /> 
            <WithPoints 
            Title="Who can apply"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "The inventor",
                "A group of inventors",
                "Organization",
                "Employer of the inventor",
                "Legal representative of the inventor"
            ]}
            /> 
            <WithPoints 
            Title="Procedure for Patent Registration"
            // Description="When you’re registering the trademark of your business brand, you get to"
            Responsibilities={[
                "Conduct a search for registered patents",
                "Prepare the application as per the norms.",
                "Filing the application at the Indian Patent Office",
                "Preserve the acknowledgement receipt and keep tracking the status from time to time"
            ]}
            /> 
            
            <WithoutPoints
            Title="Validity" 
            Paragraphs={[
              "A patent registered in India remains valid for a period of 20 years beginning from the date of filing the application. It validity remains unchanged irrespective of it being a provisional or permanent patent."
            ]}
            />
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
