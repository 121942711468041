import React from 'react'
import {Section1} from '../../components/services/Section1'
import {Section7} from '../../components/services/Section7'
import serviceimg from   "../../assets/img/service.jpg"
import { WithPoints} from '../../components/Paragraph/withPoints'
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'



export const Partnership = () => {   
    return (
        <>
        <Section1 
        title="Partnership Registration"
        subtitle={"Partnership registration allows two or more individuals to form a business together, sharing profits, responsibilities, and liabilities as per the partnership agreement."}
        backgroundImage={serviceimg}/>

<WithoutPoints
Title="What is Partnership Business" 
Paragraphs={[
    "It is a business entity that is formed, operated, and managed by a group of individuals named as partners. While most small and medium business persons especially belonging to the unorganized sector, preferred to form a partnership before, the efficacy of this kind of business is steadily losing its relevance due to the popularity and umpteen benefits that a limited liability company enjoys at present"
]}/>


<WithPoints 
Title="Why do Businessmen Choose Partnership Firm Registration?"

Responsibilities={[
"Easiest Business Format-Partnership firm is considered as one of the easiest and simplest form of business type, required minimal formalities and documentations to do firm registration",
"No Prescribed Minimum Capital-In this business structure type, there is no prescribed limit of minimum capital is required on formation. Partners can start with any capital which suits to their business",
"Ease in Decision Making-In the concept of partnership firm registration, partners can take faster decision on company formation. No need to follow either regulation for passing the resolution.",
"Comparatively Economical-In comparison to LLP and Private Limited Company; Partnership Firm is much cheaper to start and uphold. Even this business can long run with inexpensive",
"Fund Raisingt-In competency of partnership firm and it’s availability of multiple partners help leads viable contributions. Additionally, banks also consider partnership firm for sanctioning loans",
"Minimal Compliance-Partnership firm workout with very minimal compliance. Compared to LLP, Partnership has less annual and tax compliances. No need to appoint an auditor for Firm."
]}
/>



<WithPoints 
Title="Types of Partnership Firms"

Responsibilities={[
"Registered- Functions according to the rules of the Partnership Deed and the ‘Indian Partnership Act’ of 1932. It is possible to take legal action against a partner who violates the code once the business has been registered.",
"Unregistered- Depends heavily on the Partnership Deed that can be modified with the concurrence of all partners. However, the partners cannot be sued in a court of law for violating the rules.",
]}
/>

<WithPoints 
Title="Information to be included in the Partnership Deed"

Responsibilities={[
"Names & addresses of all partners",
"Partnership name",
"Date when the business became operational",
"Capital provided by each partner",
"Profit sharing percentage for each partner",
"Rules for adding more partners",
"Rules for removal of a partner",
"Rules for dissolving the partnership"
]}
/>

<WithPoints 
Title="Advantages of registering your business as a Partnership Firm?"

Responsibilities={[
"Simple, uncomplicated process requiring very less formality",
"Registration is not mandatory to form and operate a partnership business",
"However, bigger firms opt to register their business and the formalities are overseen by the ‘Registrar of Firms.",
"The company can choose to operate in any name as long as it does not violate the trade mark regulations",
"The company is not required to file their accounts annually with the Registrar unlike other business entities.",
"The firm can sue any partner or a third party for compensation, if it remains unregistered",
"It is cost effective and the expenses being considerably lesser than a LLP",
]}
/>
<WithPoints 
Title="Documents required to Register a Partnership Firm Registration"

Responsibilities={[
"Self attested PAN Card (2 Copies)",
"self attested Address Proof - Voter ID/Adhaar Card/ Driving License/Passport  (2 Copies)",
"4 passport size photographs",
"self attested Bank Statement/ Electricity Bill/ Mobile Bill/ telephone Bill not older than one month (passbook copy is not accepted)",
"Rental agreement and electricity bill of office address(Electricity bill not older than 1 month) if rented premises",
]}
/>
<WithPoints 
Title="Partnership Firm Registration Process"

Responsibilities={[
"Select the name of partnership firm",
"Prepare partnership deed or agreement for firm",
"Make an application in Form A to the Registrar of Firms for registration of partnership firm",
"Partnership deed needs to file with the Registrar",
"Pay necessary fees and stamp duty",
"Get certificate of partnership firm from Registrar"
]}
/>

<WithPoints 
Title="You will get the documents after Registration of your Company"

Responsibilities={[
"Certificate  of Partnership Firm",
"Partnership Deed",
"PAN of Partnership  Firm",
"self attested Bank Statement/ Electricity Bill/ Mobile Bill/ telephone Bill not older than one month (passbook copy is not accepted)",
"TAN of Partnership  Firm",
]}
/>

  <Section7 
sectionTitle="Join Our Community" 
description="We are trusted by over 5000+ clients. Join them now and grow your business." 
stats={[
{ value: '7518', label: 'Completed Projects' },
{ value: '5472', label: 'Satisfied Customers' },
{ value: '2184', label: 'Expert Employees' },
]}
/>
</>
  )
}
