import React from 'react'
import { useState } from 'react'
import { Section1 } from '../../components/services/Section1'
import { Section2 } from '../../components/services/Section2'
import { Section3 } from '../../components/services/Section3'
import { Section4 } from '../../components/services/Section4'
import { Section5 } from '../../components/services/Section5'
import { Section6 } from '../../components/services/Section6'
import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'
import Accordion from '../../components/accordion'





export const FoodSafety = () => {



    return (
        <>
            <Section1
                title="Food Safety and Standards Authority"
                subtitle={"Food Safety and Standards Authority ensures the safety and quality of food products by setting regulations and standards for food businesses across the country."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Food Safety and Standards Authority" 
            Paragraphs={[
              "Every Manufacturer or Trader in food products should get Centralised or State wide licence for the Premises from where he is doing the business. For getting FSSA licence the applicant should pay licence fee and the licences has maximum validity of 5 years, The licence needs to be renewed as and when it expires the renewal charges are also the same as fresh licence fee. The licence is subject to some safety conditions if those conditions are not fulfilled the licence is subject to cancellation."
            ]}
            />
            <WithoutPoints
            Title="The following persons should get Licence under FSSAI" 
            Paragraphs={[
              "Hotels, Restaurants, Food Chains, Packaged Food Manufacturers, Food Sellers and Re-sellers, Canteens in Corporate Companies, Schools, Colleges, Hospitals, Government Institutions, Food Importers and Exporters, Raw Material Suppliers to Food Businesses like Milk Dairy, poultries."
            ]}
            />
              <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />
        </>
    )
}
