import React from 'react';

export const Section5 = ({ sectionData }) => {
  return (
    <section className="wrapper !bg-[#edf2fc]">
      <div className="container pt-[4.5rem] pb-32 xl:pt-24 lg:pt-24 md:pt-24 xl:pb-60 lg:pb-60 md:pb-60 !text-center">
        <div className="flex flex-wrap mx-[-15px]">
          <div className="lg:w-10/12 xl:w-9/12 xxl:w-8/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
            {/* Dynamic Title */}
            <h2 className="!text-[.75rem] uppercase text-[#aab0bc] !mb-3 tracking-[0.02rem] leading-[1.35]">
              {sectionData?.subTitle}
            </h2>
            {/* Dynamic Sub Title */}
            <h3 className="text-[calc(1.305rem_+_0.66vw)] font-bold xl:text-[1.8rem] !leading-[1.3] mb-[5rem] xl:!mb-6 lg:!mb-6 md:!mb-6 lg:!px-10 xl:!px-10">
              {sectionData?.title}
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};
