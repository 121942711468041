import React from 'react'

import { Section1 } from '../../components/services/Section1'

import { Section7 } from '../../components/services/Section7'
import serviceimg from "../../assets/img/service.jpg"
import { WithoutPoints } from '../../components/Paragraph/withoutPoints'
import { WithPoints } from '../../components/Paragraph/withPoints'





export const ShopRegistration = () => {


    return (
        <>
            <Section1
                title="Shop and Establishment Registration"
                subtitle={"Shop and Establishment Registration ensures legal recognition for businesses operating within a specific state, regulating working hours, wages, and other employment conditions."}
                backgroundImage={serviceimg} />
            
            <WithoutPoints
            Title="Karnataka Shops and Establishment Act Registration" 
            Paragraphs={[
              "The term shop and establishment suggests – or rather includes – all entities that want to operate as a shop in a particular state of India. If you’re doing business in Karnataka and want to work in such a capacity in India; this is something necessary that you must understand. For most businesses in Karnataka; it is mandatory to be registered under this particular Act. This can be called the first step that you would take towards the business world that offers you such immense promise.",
              "Normally, each state has its own shop and establishment-related rules and regulations. This also means that the rules of such an act would be different from one state to another.",
              "So before going to acquire shop and establishment license certificate in Bangalore; you need to know and understand the overall process about it; because you’re a businessman and doing business in this place to uplift your business.",
              "However, shops and establishment registration is compulsory for all companies, businesses, legal entities and proprietors those who are doing commerce in the state or who’ve already been setup their establishments, but has not registered yet. Please take the certificate and be free from the penalty."
            ]}
            />
            {/* <WithoutPoints
            Title="Indirect Taxes & GST" 
            Paragraphs={[
              "However, GST is not confined to merely bringing all sorts of service taxations under one umbrella, it is also about the manner in which the taxes get to be imposed. The businesspersons have to remain aware about the exact points when the excise duty, VAT, CST is added to the price of goods or products. This makes the system ridiculously complex for both the buyer as well as the seller to understand and implement correctly. GST is supposed to simplify the process with the end users being able to benefit from the transparency as well as the pricing in a big way."
            ]}
            /> */}
            {/* <WithoutPoints
            Title="When will GST be imposed on your supplies?" 
            Paragraphs={[
              "The ‘GST Act’ advises the implementation of GST at the point of ‘Supply.’ While the term is meant for selling of goods and/or services predominantly, it also includes lease, rentals, barter, and transfer to an agent or a dealer along with a branch of your company. The GST should be paid by you during purchase of the goods and you will be entitled to add the tax on your products/goods during supply. However, it would be wise to check whether your business transaction has been included in the list of supplies, otherwise GST would not be levied."
            ]}
            /> */}


            {/* <WithoutPoints
            Title="Types" 
            Paragraphs={[
              "The trademarks are beneficial for both consumers as well as businesses. The businesses can create an identity of their own with the trademarks. They can also develop a market reputation with their customers and focus on the growth of their enterprises. The trademarks allow and influence the prospective customers to make decisions on purchasing when it comes to buy the specific products that they are looking to have. Looking for favorite brands becomes a lot easier when there are distinct trademarks to identify with them. It also helps to steer clear of brands that are otherwise not all that reliable."
            ]}
            /> */}
            {/* <WithPoints 
            Responsibilities={[
                "Although the supplies made to countries out of India will not be taxed with GST, you still need to register for making the supplies.",
                "Both Central (CGST) as well as State taxes (SGST) will be levied on Intra state sales.",
                "IGST is applicable for interstate sales that amount to the total sum of CGST and SGST plus charges on imports."
            ]}
            />  */}

            <WithPoints 
            Title="Karnataka Shop and Establishment Act"
            Description="The Karnataka Shops & Commercial Establishments Act 1962 deals with shops and commercial establishments in the state. It primarily regulates their operation. The Act was introduced with the aim of regulate the conditions of work of people employed in these establishments. This includes the following factors:"
            Responsibilities={[
                "Employees working hours",
                "Yearly & monthly leaves with wages",
                "Wages and compensation",
                "Employment of women and children and other aspects"
            ]}
            /> 

            <WithPoints 
            Title="Who is Eligible to Register Under This Act?"
            Description="As Karnataka Shops & Commercial Establishments Act 1962 provides a clear definition for the terms establishment, shop, and commercial establishment and it states, these types of entities have to acquire the registration certificate.
            The term “Establishment” means any shop or commercial establishment.
            The term “Commercial Establishment” means a commercial or trading entity. It could be an insurance or banking establishment. It could also be an establishment that provides administrative services where people are doing office work."
            Responsibilities={[
                "Hotels",
                "Restaurants",
                "Boarding houses",
                "Eating houses",
                "Cafes",
                "Refreshment houses",
                "Theatres",
                "Places of public amusement and entertainment",
                "Banking body",
                "Insurance unit"
            ]}
            /> 
            
            
            <WithoutPoints
            Title="What are Enrollment and Registration Certificates? Who Needs Them?" 
            Paragraphs={[
              "Basically, it would include any establishment that the Karnataka government denotes as a commercial establishment.",
            "As per the Act the term “Shop” means any premises where business or trade is being done. It could also be one where services are provided to customers. This includes the likes of the following:"
            ]}
            />

<WithPoints 
            
            Responsibilities={[
                "Offices",
                "Storerooms",
                "Godowns",
                "Warehouses",
            ]}
            /> 

            <WithoutPoints
            Paragraphs={[
              "It does not matter where they are located. They could be located in the same premises or elsewhere in state. As long as any trade or business is being done over there it would come under the ambit of the Act.",
              "There are certain establishments that are however exempted from registration under the Act. Examples of such establishments would be offices owned and undertaken by governmental authorities with the exception of commercial undertakings. This includes central, state, and local governmental entities."
            ]}
            />

            <WithPoints 
            Title="Documents Required for Shops & Establishment Registration in Bangalore"
            Description="You need to provide the following details at the time of applying for registration under the Karnataka Shops & Commercial Establishments Act 1962:"
            Responsibilities={[
                "Certificate of incorporation of company",
                "PAN (Permanent Account Number) of the company",
                "MOA (memorandum of association)",
                "Address proof of company director such as rental or lease agreement",
                "AOA (article of association)",
                "ID (identification) proof of director – PAN card, passport, voter ID card, and driving license",
                "Lease agreement or rental agreement of the company",
                "List of directors"
            ]}
            /> 
            <WithPoints 
            Description="You also need to provide some information in this regard:"
            Responsibilities={[
                "Name of company",
                "Details of authorized signatory – this is in case the director is not signing",
                "Postal address",
                "Nature of business",
                "Telephone number",
                "Date of commencement of business",
                "Email address",
                "Number of people working in the company – female and male employees have to be mentioned separately",
                "Details of director, proprietor, or managing director such as address, email ID, and phone number",
                "Weekly holiday"
            ]}
            /> 



<WithPoints 
            Title="Process of Application for Registration"
            Description="Normally the labour department of the state government governs the Act. This means that you have to apply for shop registration of your business with the respective local labour officer in your district. Suppose your business place is Bangalore, then you’ve to apply in Bangalore labour department. If your business is eligible to register under the Act you would have to apply for registration within a span of 30 days of starting your business.
            Here’s some steps are involved in this process:"
            Responsibilities={[
                "Visit to e-Karmika website",
                "Click on “New Registrations”; enter the information regarding your business, security code and click on “Submit” button",
                "Login with received “Username and Password” to your given Mobile No and Email, click on “Registration (Part-A)” and fill the form & upload scanned documents",
                "Make online payment consequently",
                "Submit the physical “acknowledgement copy of application” to your respective circle officer with payment challan, documents and get the certificate"
            ]}
            /> 

<WithoutPoints
            // Title="Process of Application for Registration" 
            Paragraphs={[
              "The application need to be applied online through e-Karmika portal which is the state government’s official website, provides facilities for registration, renewal and etc. under the Act.",
            "The application has to be made in Form A along with necessary documents. This is from the date when your establishment started functioning. There is a prescribed form for this purpose. You also have to pay a certain fees in this case that tends to change from time to time.",
            "If the officer in question is satisfied with your application your registration certificate under the Act would be issued."
            ]}
            />
<WithoutPoints
            Title="Validity and Renewal" 
            Paragraphs={[
              "The registration certificate is valid for a period of 5 years. And the license has to be renewed before its expiry."
            ]}
            />
            
            
            <Section7
                sectionTitle="Join Our Community"
                description="We are trusted by over 5000+ clients. Join them now and grow your business."
                stats={[
                    { value: '7518', label: 'Completed Projects' },
                    { value: '5472', label: 'Satisfied Customers' },
                    { value: '2184', label: 'Expert Employees' },
                ]}
            />

        </>
    )
}
